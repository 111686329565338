<template>
    <div class="table-responsive">
        <table class="table-bordered table">
            <tbody>
                <tr>
                    <th colspan="4">＜CP概要＞</th>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン名</td>
                    <td colspan="3">{{ detailBeginForDocument.name }}</td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">顧客様情報</td>
                    <td colspan="3">
                        {{ detailBeginForDocument.clients_formatted
                        }}{{ detailBeginForDocument.agencies_formatted ? '/' + detailBeginForDocument.agencies_formatted : '' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン期間</td>
                    <td colspan="3">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date)
                        }}{{ detailBeginForDocument.campaign_end_date ? '～' + renderDate1(detailBeginForDocument.campaign_end_date) : '' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">CPサイト・CPツイート</td>
                    <td colspan="3">{{ detailBeginForDocument.url }}</td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">ディレクション担当</td>
                    <td colspan="3">
                        {{ detailBeginForDocument.person_in_charge ? detailBeginForDocument.person_in_charge.name : '' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">営業担当</td>
                    <td colspan="3">{{ detailBeginForDocument.sale_staff ? detailBeginForDocument.sale_staff.name : '' }}</td>
                </tr>
                <tr>
                    <th colspan="2">■CP概要</th>
                    <th>1次チェック</th>
                    <th>2次チェック</th>
                </tr>
                <tr v-if="dataObject.address.data">
                    <td class="bg-color1">宛先</td>
                    <td>
                        {{ dataObject.address.data }}
                        <!--                        <input type="text" class="form-control" v-model="dataObject.address.data" />-->
                        <!--                        <multiselect-->
                        <!--                            :multiple="true"-->
                        <!--                            :showPointer="false"-->
                        <!--                            :showNoResults="false"-->
                        <!--                            selectedLabel=""-->
                        <!--                            deselectLabel=""-->
                        <!--                            placeholder=""-->
                        <!--                            selectLabel=""-->
                        <!--                            :showNoOptions="false"-->
                        <!--                            v-model="dataObject.address.data"-->
                        <!--                            :taggable="true"-->
                        <!--                            :options="option.address"-->
                        <!--                            @tag="addTag($event, 'address')"-->
                        <!--                        ></multiselect>-->
                    </td>
                    <td>
                        <label for="" v-if="dataObject.address.first_check && dataObject.address.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address', 'first_check')"
                            />
                            <span v-if="dataObject.address.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address.first_check.id"
                                @click="
                                    dataObject.address.first_check = { id: '' };
                                    dataObject.address.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address.first_check.id" class="ml-1">{{
                                dataObject.address.first_check.name ? dataObject.address.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.address.secondary_check && dataObject.address.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address', 'secondary_check')"
                            />
                            <span v-if="dataObject.address.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address.secondary_check.id"
                                @click="
                                    dataObject.address.secondary_check = { id: '' };
                                    dataObject.address.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address.secondary_check.id" class="ml-1">{{
                                dataObject.address.secondary_check.name ? dataObject.address.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="4">■応募数報告</th>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color1">報告スケジュール</td>
                    <td>
                        {{ dataObject.reporting_schedule.data }}
                        <!--                        <multiselect-->
                        <!--                            :multiple="true"-->
                        <!--                            :showPointer="false"-->
                        <!--                            :showNoResults="false"-->
                        <!--                            selectedLabel=""-->
                        <!--                            deselectLabel=""-->
                        <!--                            placeholder=""-->
                        <!--                            selectLabel=""-->
                        <!--                            :showNoOptions="false"-->
                        <!--                            v-model="dataObject.reporting_schedule.data"-->
                        <!--                            :taggable="true"-->
                        <!--                            :options="option.reporting_schedule"-->
                        <!--                            @tag="addTag($event, 'reporting_schedule')"-->
                        <!--                        ></multiselect>-->
                    </td>
                    <td>
                        <label for="" v-if="dataObject.reporting_schedule.first_check && dataObject.reporting_schedule.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule', 'first_check')"
                            />
                            <span v-if="dataObject.reporting_schedule.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.first_check.id"
                                @click="
                                    dataObject.reporting_schedule.first_check = { id: '' };
                                    dataObject.reporting_schedule.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule.first_check.id" class="ml-1">{{
                                dataObject.reporting_schedule.first_check.name ? dataObject.reporting_schedule.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.reporting_schedule.secondary_check && dataObject.reporting_schedule.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule', 'secondary_check')"
                            />
                            <span v-if="dataObject.reporting_schedule.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.secondary_check.id"
                                @click="
                                    dataObject.reporting_schedule.secondary_check = { id: '' };
                                    dataObject.reporting_schedule.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule.secondary_check.id" class="ml-1">{{
                                dataObject.reporting_schedule.secondary_check.name ? dataObject.reporting_schedule.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.reporting_schedule_detail.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.reporting_schedule_detail.first_check && dataObject.reporting_schedule_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule_detail', 'first_check')"
                            />
                            <span v-if="dataObject.reporting_schedule_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.first_check.id"
                                @click="
                                    dataObject.reporting_schedule_detail.first_check = { id: '' };
                                    dataObject.reporting_schedule_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule_detail.first_check.id" class="ml-1">{{
                                dataObject.reporting_schedule_detail.first_check.name ? dataObject.reporting_schedule_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.reporting_schedule_detail.secondary_check && dataObject.reporting_schedule_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.reporting_schedule_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.secondary_check.id"
                                @click="
                                    dataObject.reporting_schedule_detail.secondary_check = { id: '' };
                                    dataObject.reporting_schedule_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule_detail.secondary_check.id" class="ml-1">{{
                                dataObject.reporting_schedule_detail.secondary_check.name
                                    ? dataObject.reporting_schedule_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color1">仕分け方法</td>
                    <td>
                        <textarea v-model="dataObject.sorting_method.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.sorting_method.first_check && dataObject.sorting_method.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.sorting_method.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'sorting_method', 'first_check')"
                            />
                            <span v-if="dataObject.sorting_method.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.sorting_method.first_check.id"
                                @click="
                                    dataObject.sorting_method.first_check = { id: '' };
                                    dataObject.sorting_method.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.sorting_method.first_check.id" class="ml-1">{{
                                dataObject.sorting_method.first_check.name ? dataObject.sorting_method.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.sorting_method.secondary_check && dataObject.sorting_method.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.sorting_method.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'sorting_method', 'secondary_check')"
                            />
                            <span v-if="dataObject.sorting_method.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.sorting_method.secondary_check.id"
                                @click="
                                    dataObject.sorting_method.secondary_check = { id: '' };
                                    dataObject.sorting_method.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.sorting_method.secondary_check.id" class="ml-1">{{
                                dataObject.sorting_method.secondary_check.name ? dataObject.sorting_method.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="3" class="bg-color1">報告内容</td>
                    <td>
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.contents_of_report.data"
                            :taggable="true"
                            :options="option.contents_of_report"
                            @tag="addTag($event, 'contents_of_report')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.contents_of_report.first_check && dataObject.contents_of_report.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report', 'first_check')"
                            />
                            <span v-if="dataObject.contents_of_report.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.first_check.id"
                                @click="
                                    dataObject.contents_of_report.first_check.id = { id: '' };
                                    dataObject.contents_of_report.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report.first_check.id" class="ml-1">{{
                                dataObject.contents_of_report.first_check.name ? dataObject.contents_of_report.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.contents_of_report.secondary_check && dataObject.contents_of_report.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report', 'secondary_check')"
                            />
                            <span v-if="dataObject.contents_of_report.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.secondary_check.id"
                                @click="
                                    dataObject.contents_of_report.secondary_check = { id: '' };
                                    dataObject.contents_of_report.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report.secondary_check.id" class="ml-1">{{
                                dataObject.contents_of_report.secondary_check.name ? dataObject.contents_of_report.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.contents_of_report_detail.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.contents_of_report_detail.first_check && dataObject.contents_of_report_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_detail', 'first_check')"
                            />
                            <span v-if="dataObject.contents_of_report_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.first_check.id"
                                @click="
                                    dataObject.contents_of_report_detail.first_check = { id: '' };
                                    dataObject.contents_of_report_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_detail.first_check.id" class="ml-1">{{
                                dataObject.contents_of_report_detail.first_check.name ? dataObject.contents_of_report_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.contents_of_report_detail.secondary_check && dataObject.contents_of_report_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.contents_of_report_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.secondary_check.id"
                                @click="
                                    dataObject.contents_of_report_detail.secondary_check = { id: '' };
                                    dataObject.contents_of_report_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_detail.secondary_check.id" class="ml-1">{{
                                dataObject.contents_of_report_detail.secondary_check.name
                                    ? dataObject.contents_of_report_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.contents_of_report_expiration_date.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.contents_of_report_expiration_date.first_check &&
                                dataObject.contents_of_report_expiration_date.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_expiration_date.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_expiration_date', 'first_check')"
                            />
                            <span v-if="dataObject.contents_of_report_expiration_date.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_expiration_date.first_check.id"
                                @click="
                                    dataObject.contents_of_report_expiration_date.first_check = { id: '' };
                                    dataObject.contents_of_report_expiration_date.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_expiration_date.first_check.id" class="ml-1">{{
                                dataObject.contents_of_report_expiration_date.first_check.name
                                    ? dataObject.contents_of_report_expiration_date.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.contents_of_report_expiration_date.secondary_check &&
                                dataObject.contents_of_report_expiration_date.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_expiration_date.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_expiration_date', 'secondary_check')"
                            />
                            <span v-if="dataObject.contents_of_report_expiration_date.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_expiration_date.secondary_check.id"
                                @click="
                                    dataObject.contents_of_report_expiration_date.secondary_check = { id: '' };
                                    dataObject.contents_of_report_expiration_date.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_expiration_date.secondary_check.id" class="ml-1">{{
                                dataObject.contents_of_report_expiration_date.secondary_check.name
                                    ? dataObject.contents_of_report_expiration_date.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="4">■その他(特記)</th>
                </tr>
                <tr>
                    <td class="bg-color1">無効内容の詳細</td>
                    <td>
                        <textarea v-model="dataObject.invalid_content.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.invalid_content.first_check && dataObject.invalid_content.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'invalid_content', 'first_check')"
                            />
                            <span v-if="dataObject.invalid_content.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.first_check.id"
                                @click="
                                    dataObject.invalid_content.first_check = { id: '' };
                                    dataObject.invalid_content.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.invalid_content.first_check.id" class="ml-1">{{
                                dataObject.invalid_content.first_check.name ? dataObject.invalid_content.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.invalid_content.secondary_check && dataObject.invalid_content.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'invalid_content', 'secondary_check')"
                            />
                            <span v-if="dataObject.invalid_content.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.secondary_check.id"
                                @click="
                                    dataObject.invalid_content.secondary_check = { id: '' };
                                    dataObject.invalid_content.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.invalid_content.secondary_check.id" class="ml-1">{{
                                dataObject.invalid_content.secondary_check.name ? dataObject.invalid_content.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color1">その他連絡事項</td>
                    <td>
                        <textarea v-model="dataObject.other_information.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_information.first_check && dataObject.other_information.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_information', 'first_check')"
                            />
                            <span v-if="dataObject.other_information.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.first_check.id"
                                @click="
                                    dataObject.other_information.first_check = { id: '' };
                                    dataObject.other_information.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_information.first_check.id" class="ml-1">{{
                                dataObject.other_information.first_check.name ? dataObject.other_information.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_information.secondary_check && dataObject.other_information.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_information', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_information.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.secondary_check.id"
                                @click="
                                    dataObject.other_information.secondary_check = { id: '' };
                                    dataObject.other_information.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_information.secondary_check.id" class="ml-1">{{
                                dataObject.other_information.secondary_check.name ? dataObject.other_information.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'Tab1Require',
    props: {
        dataObject: {
            type: Object,
            required: false
        },
        currentUser: {
            type: Object,
            required: false
        },
        detailBeginForDocument: {
            type: Object,
            required: false
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            option: {
                address: ['直住所', '私書箱', 'その他'],
                reporting_schedule: ['週報', '月報', '最終一括', 'その他イレギュラー'],
                contents_of_report: ['週別応募件数', '賞別応募件数', '有効無効応募件数']
            }
        };
    },
    methods: {
        renderDate(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                return `${year} 年 ${month} 月 ${day} 日`;
            }
            return '';
        },
        renderDate1(time) {
            const daysInJapanese = ['日', '月', '火', '水', '木', '金', '土'];
            const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = addLeadingZero(date.getHours());
                const minutes = addLeadingZero(date.getMinutes());
                const dayOfWeek = date.getDay();
                const dayNameInJapanese = daysInJapanese[dayOfWeek];
                return `${year} 年 ${month} 月 ${day} 日（${dayNameInJapanese}）${hours}：${minutes}`;
            }
            return '';
        },
        addTag(newTag, key) {
            console.log(key);
            this.option[key].push(newTag);
            this.dataObject[key].data.push(newTag);
        },
        updateCheckbox(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject[key][key2].id = value;
            } else {
                this.dataObject[key][key2].id = null;
            }
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped>
.box8 input[type='text'] {
    width: 200px;
}

.box8 textarea.form-control {
    //width: 400px;
}
</style>
