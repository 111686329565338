const ORDER_REQUIRED = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'upload-file',
    'date',
    'fullname',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box',
    'get-param'
];

const LABEL = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'div_1',
    'upload-file',
    'date',
    'fullname',
    'postcode-and-province',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box'
];

const DISPLAY_CONFIRM = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'div_1',
    'div_2',
    'upload-file',
    'date',
    'fullname',
    'postcode-and-province',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box'
];

const PERSONAL_SETTINGS = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'upload-file',
    'date',
    'fullname',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box',
    'get-param'
];

const LAYOUT = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'div_2',
    'upload-file',
    'date',
    'fullname',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box'
];

const BACKGROUND_COLOR = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'div_1',
    'div_2',
    'upload-file',
    'date',
    'fullname',
    'postcode-and-province',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box'
];

const INPUT_CONDITIONS = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'div_1',
    'div_2',
    'upload-file',
    'date',
    'fullname',
    'postcode-and-province',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box'
];

const CONDITIONS_DO_NOT_MATCH = [
    'radio',
    'select',
    'checkbox',
    'input',
    'textarea',
    'matrix',
    'upload-file',
    'date',
    'fullname',
    'postcode',
    'province',
    'phone',
    'remaining-seats-management-box',
    'keyword-box'
];

export { ORDER_REQUIRED, LABEL, DISPLAY_CONFIRM, PERSONAL_SETTINGS, LAYOUT, BACKGROUND_COLOR, INPUT_CONDITIONS, CONDITIONS_DO_NOT_MATCH };
