<template>
    <div class="table-responsive">
        <table class="table-bordered table">
            <tbody>
                <tr>
                    <th colspan="8">【抽選】CR</th>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン名</td>
                    <td colspan="7">{{ detailBeginForDocument.name }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">顧客様情報</td>
                    <td colspan="7">
                        {{ detailBeginForDocument.clients_formatted
                        }}{{ detailBeginForDocument.agencies_formatted ? '/' + detailBeginForDocument.agencies_formatted : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン期間</td>
                    <td colspan="7">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date)
                        }}{{ detailBeginForDocument.campaign_end_date ? '～' + renderDate1(detailBeginForDocument.campaign_end_date) : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">CPサイト・CPツイート</td>
                    <td colspan="7">{{ detailBeginForDocument.url }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">ディレクション担当</td>
                    <td colspan="7">
                        {{ detailBeginForDocument.person_in_charge ? detailBeginForDocument.person_in_charge.name : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">営業担当</td>
                    <td colspan="7">{{ detailBeginForDocument.sale_staff ? detailBeginForDocument.sale_staff.name : '' }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>

                <tr>
                    <th colspan="8">＜抽選条件＞※応募割れの場合は抽選条件が変わる可能性があります。</th>
                </tr>
                <template v-if="dataObject.prizes && dataObject.prizes.data.length > 0">
                    <tr>
                        <th :rowspan="dataObject.prizes.data.length + 1" class="bg-color5">当選人数</th>
                        <th class="bg-color5"></th>
                        <th class="bg-color5">当選者</th>
                        <th class="bg-color5">予備当選者</th>
                        <th colspan="2" class="bg-color5">計</th>
                        <th>1次チェック</th>
                        <th>2次チェック</th>
                    </tr>
                    <tr v-for="(item, k) in dataObject.prizes.data" :key="item.name">
                        <td class="bg-color5">{{ item.name }}賞</td>
                        <td>
                            <input type="number" v-model="dataObject.prizes.data[k].number_of_win_prize" class="form-control" />
                        </td>
                        <td>
                            <input type="number" v-model="dataObject.prizes.data[k].preliminary_winner" class="form-control" />
                        </td>
                        <td>
                            {{ item.total_winner }}
                        </td>
                        <td v-if="k == 0" :rowspan="dataObject.prizes.data.length" align="middle" valign="middle">
                            {{ dataObject.prizes.sum_total_winner }}
                        </td>
                        <td>
                            <label for="" v-if="item.first_check && item.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, k, 'first_check')"
                                />
                                <span v-if="item.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.first_check.id"
                                    @click="
                                        item.first_check = { id: '' };
                                        item.first_check.change = true;
                                    "
                                />
                                <span v-if="item.first_check.id" class="ml-1">{{ item.first_check.name ? item.first_check.name : '' }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="item.secondary_check && item.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, k, 'secondary_check')"
                                />
                                <span v-if="item.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.secondary_check.id"
                                    @click="
                                        item.secondary_check = { id: '' };
                                        item.secondary_check.change = true;
                                    "
                                />
                                <span v-if="item.secondary_check.id" class="ml-1">{{
                                    item.secondary_check.name ? item.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <tr>
                    <td colspan="2" rowspan="2" class="bg-color5">抽選方法</td>
                    <td colspan="4">
                        {{ dataObject.lottery_method.data }}
                        <!--                        <multiselect-->
                        <!--                            :multiple="true"-->
                        <!--                            :showPointer="false"-->
                        <!--                            :showNoResults="false"-->
                        <!--                            selectedLabel=""-->
                        <!--                            deselectLabel=""-->
                        <!--                            placeholder=""-->
                        <!--                            selectLabel=""-->
                        <!--                            :showNoOptions="false"-->
                        <!--                            v-model="dataObject.lottery_method.data"-->
                        <!--                            :taggable="true"-->
                        <!--                            :options="option.lottery_method"-->
                        <!--                            @tag="addTag($event, 'lottery_method')"-->
                        <!--                        ></multiselect>-->
                    </td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method.first_check && dataObject.lottery_method.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method', 'first_check')"
                            />
                            <span v-if="dataObject.lottery_method.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.first_check.id"
                                @click="
                                    dataObject.lottery_method.first_check = { id: '' };
                                    dataObject.lottery_method.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method.first_check.id" class="ml-1">{{
                                dataObject.lottery_method.first_check.name ? dataObject.lottery_method.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method.secondary_check && dataObject.lottery_method.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method', 'secondary_check')"
                            />
                            <span v-if="dataObject.lottery_method.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.secondary_check.id"
                                @click="
                                    dataObject.lottery_method.secondary_check = { id: '' };
                                    dataObject.lottery_method.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method.secondary_check.id" class="ml-1">{{
                                dataObject.lottery_method.secondary_check.name ? dataObject.lottery_method.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.lottery_method_detail.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method_detail.first_check && dataObject.lottery_method_detail.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method_detail', 'first_check')"
                            />
                            <span v-if="dataObject.lottery_method_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method_detail.first_check.id"
                                @click="
                                    dataObject.lottery_method_detail.first_check = { id: '' };
                                    dataObject.lottery_method_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method_detail.first_check.id" class="ml-1">{{
                                dataObject.lottery_method_detail.first_check.name ? dataObject.lottery_method_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.lottery_method_detail.secondary_check && dataObject.lottery_method_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.lottery_method_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method_detail.secondary_check.id"
                                @click="
                                    dataObject.lottery_method_detail.secondary_check = { id: '' };
                                    dataObject.lottery_method_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method_detail.secondary_check.id" class="ml-1">{{
                                dataObject.lottery_method_detail.secondary_check.name ? dataObject.lottery_method_detail.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color5">ダウンロード</td>
                    <td class="bg-color5">キャンペーンID</td>
                    <td colspan="4"><textarea v-model="dataObject.campaign_id_record.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.campaign_id_record.first_check && dataObject.campaign_id_record.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_id_record.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'campaign_id_record', 'first_check')"
                            />
                            <span v-if="dataObject.campaign_id_record.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_id_record.first_check.id"
                                @click="
                                    dataObject.campaign_id_record.first_check = { id: '' };
                                    dataObject.campaign_id_record.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.campaign_id_record.first_check.id" class="ml-1">{{
                                dataObject.campaign_id_record.first_check.name ? dataObject.campaign_id_record.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.campaign_id_record.secondary_check && dataObject.campaign_id_record.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_id_record.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'campaign_id_record', 'secondary_check')"
                            />
                            <span v-if="dataObject.campaign_id_record.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_id_record.secondary_check.id"
                                @click="
                                    dataObject.campaign_id_record.secondary_check = { id: '' };
                                    dataObject.campaign_id_record.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.campaign_id_record.secondary_check.id" class="ml-1">{{
                                dataObject.campaign_id_record.secondary_check.name ? dataObject.campaign_id_record.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color5">キャンペーンタイトル</td>
                    <td colspan="4"><textarea v-model="dataObject.campaign_title.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.campaign_title.first_check && dataObject.campaign_title.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_title.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'campaign_title', 'first_check')"
                            />
                            <span v-if="dataObject.campaign_title.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_title.first_check.id"
                                @click="
                                    dataObject.campaign_title.first_check = { id: '' };
                                    dataObject.campaign_title.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.campaign_title.first_check.id" class="ml-1">{{
                                dataObject.campaign_title.first_check.name ? dataObject.campaign_title.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.campaign_title.secondary_check && dataObject.campaign_title.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_title.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'campaign_title', 'secondary_check')"
                            />
                            <span v-if="dataObject.campaign_title.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.campaign_title.secondary_check.id"
                                @click="
                                    dataObject.campaign_title.secondary_check = { id: '' };
                                    dataObject.campaign_title.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.campaign_title.secondary_check.id" class="ml-1">{{
                                dataObject.campaign_title.secondary_check.name ? dataObject.campaign_title.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color5">応募有効期間</td>
                    <td colspan="4">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date) }}～{{ renderDate1(detailBeginForDocument.campaign_end_date) }}
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.application_validity_period.first_check && dataObject.application_validity_period.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.application_validity_period.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'application_validity_period', 'first_check')"
                            />
                            <span v-if="dataObject.application_validity_period.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.application_validity_period.first_check.id"
                                @click="
                                    dataObject.application_validity_period.first_check = { id: '' };
                                    dataObject.application_validity_period.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.application_validity_period.first_check.id" class="ml-1">{{
                                dataObject.application_validity_period.first_check.name ? dataObject.application_validity_period.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.application_validity_period.secondary_check &&
                                dataObject.application_validity_period.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.application_validity_period.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'application_validity_period', 'secondary_check')"
                            />
                            <span v-if="dataObject.application_validity_period.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.application_validity_period.secondary_check.id"
                                @click="
                                    dataObject.application_validity_period.secondary_check = { id: '' };
                                    dataObject.application_validity_period.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.application_validity_period.secondary_check.id" class="ml-1">{{
                                dataObject.application_validity_period.secondary_check.name
                                    ? dataObject.application_validity_period.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="3" class="bg-color5">重複条件</td>
                    <td class="bg-color5">賞品またぎ</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.prize_winning.data"
                            :taggable="true"
                            :options="option.prize_winning"
                            @tag="addTag($event, 'prize_winning')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.prize_winning.first_check && dataObject.prize_winning.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_winning.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'prize_winning', 'first_check')"
                            />
                            <span v-if="dataObject.prize_winning.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_winning.first_check.id"
                                @click="
                                    dataObject.prize_winning.first_check = { id: '' };
                                    dataObject.prize_winning.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.prize_winning.first_check.id" class="ml-1">{{
                                dataObject.prize_winning.first_check.name ? dataObject.prize_winning.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.prize_winning.secondary_check && dataObject.prize_winning.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_winning.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'prize_winning', 'secondary_check')"
                            />
                            <span v-if="dataObject.prize_winning.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_winning.secondary_check.id"
                                @click="
                                    dataObject.prize_winning.secondary_check = { id: '' };
                                    dataObject.prize_winning.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.prize_winning.secondary_check.id" class="ml-1">{{
                                dataObject.prize_winning.secondary_check.name ? dataObject.prize_winning.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color5">応募形態またぎ</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.cross_application_form.data"
                            :taggable="true"
                            :options="option.cross_application_form"
                            @tag="addTag($event, 'cross_application_form')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.cross_application_form.first_check && dataObject.cross_application_form.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_application_form.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'cross_application_form', 'first_check')"
                            />
                            <span v-if="dataObject.cross_application_form.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_application_form.first_check.id"
                                @click="
                                    dataObject.cross_application_form.first_check = { id: '' };
                                    dataObject.cross_application_form.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.cross_application_form.first_check.id" class="ml-1">{{
                                dataObject.cross_application_form.first_check.name ? dataObject.cross_application_form.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.cross_application_form.secondary_check && dataObject.cross_application_form.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_application_form.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'cross_application_form', 'secondary_check')"
                            />
                            <span v-if="dataObject.cross_application_form.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_application_form.secondary_check.id"
                                @click="
                                    dataObject.cross_application_form.secondary_check = { id: '' };
                                    dataObject.cross_application_form.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.cross_application_form.secondary_check.id" class="ml-1">{{
                                dataObject.cross_application_form.secondary_check.name ? dataObject.cross_application_form.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color5">流通またぎ</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.cross_distribution.data"
                            :taggable="true"
                            :options="option.cross_distribution"
                            @tag="addTag($event, 'cross_distribution')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.cross_distribution.first_check && dataObject.cross_distribution.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'cross_distribution', 'first_check')"
                            />
                            <span v-if="dataObject.cross_distribution.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution.first_check.id"
                                @click="
                                    dataObject.cross_distribution.first_check = { id: '' };
                                    dataObject.cross_distribution.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.cross_distribution.first_check.id" class="ml-1">{{
                                dataObject.cross_distribution.first_check.name ? dataObject.cross_distribution.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.cross_distribution.secondary_check && dataObject.cross_distribution.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'cross_distribution', 'secondary_check')"
                            />
                            <span v-if="dataObject.cross_distribution.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution.secondary_check.id"
                                @click="
                                    dataObject.cross_distribution.secondary_check = { id: '' };
                                    dataObject.cross_distribution.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.cross_distribution.secondary_check.id" class="ml-1">{{
                                dataObject.cross_distribution.secondary_check.name ? dataObject.cross_distribution.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" rowspan="2" class="bg-color5">比率指定</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.ratio_specification.data"
                            :taggable="true"
                            :options="option.ratio_specification"
                            @tag="addTag($event, 'ratio_specification')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.ratio_specification.first_check && dataObject.ratio_specification.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification', 'first_check')"
                            />
                            <span v-if="dataObject.ratio_specification.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification.first_check.id"
                                @click="
                                    dataObject.ratio_specification.first_check = { id: '' };
                                    dataObject.ratio_specification.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification.first_check.id" class="ml-1">{{
                                dataObject.ratio_specification.first_check.name ? dataObject.ratio_specification.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.ratio_specification.secondary_check && dataObject.ratio_specification.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification', 'secondary_check')"
                            />
                            <span v-if="dataObject.ratio_specification.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification.secondary_check.id"
                                @click="
                                    dataObject.ratio_specification.secondary_check = { id: '' };
                                    dataObject.ratio_specification.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification.secondary_check.id" class="ml-1">{{
                                dataObject.ratio_specification.secondary_check.name ? dataObject.ratio_specification.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.ratio_specification_detail.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.ratio_specification_detail.first_check && dataObject.ratio_specification_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_detail', 'first_check')"
                            />
                            <span v-if="dataObject.ratio_specification_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_detail.first_check.id"
                                @click="
                                    dataObject.ratio_specification_detail.first_check = { id: '' };
                                    dataObject.ratio_specification_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_detail.first_check.id" class="ml-1">{{
                                dataObject.ratio_specification_detail.first_check.name ? dataObject.ratio_specification_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.ratio_specification_detail.secondary_check && dataObject.ratio_specification_detail.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.ratio_specification_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_detail.secondary_check.id"
                                @click="
                                    dataObject.ratio_specification_detail.secondary_check = { id: '' };
                                    dataObject.ratio_specification_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_detail.secondary_check.id" class="ml-1">{{
                                dataObject.ratio_specification_detail.secondary_check.name
                                    ? dataObject.ratio_specification_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" class="bg-color5" rowspan="2">その他条件</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.other_conditions.data"
                            :taggable="true"
                            :options="option.other_conditions"
                            @tag="addTag($event, 'other_conditions')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_conditions.first_check && dataObject.other_conditions.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_conditions', 'first_check')"
                            />
                            <span v-if="dataObject.other_conditions.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.first_check.id"
                                @click="
                                    dataObject.other_conditions.first_check = { id: '' };
                                    dataObject.other_conditions.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_conditions.first_check.id" class="ml-1">{{
                                dataObject.other_conditions.first_check.name ? dataObject.other_conditions.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_conditions.secondary_check && dataObject.other_conditions.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_conditions', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_conditions.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.secondary_check.id"
                                @click="
                                    dataObject.other_conditions.secondary_check = { id: '' };
                                    dataObject.other_conditions.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_conditions.secondary_check.id" class="ml-1">{{
                                dataObject.other_conditions.secondary_check.name ? dataObject.other_conditions.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.other_condition_detail.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.other_condition_detail.first_check && dataObject.other_condition_detail.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_condition_detail', 'first_check')"
                            />
                            <span v-if="dataObject.other_condition_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_detail.first_check.id"
                                @click="
                                    dataObject.other_condition_detail.first_check = { id: '' };
                                    dataObject.other_condition_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_condition_detail.first_check.id" class="ml-1">{{
                                dataObject.other_condition_detail.first_check.name ? dataObject.other_condition_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.other_condition_detail.secondary_check && dataObject.other_condition_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_condition_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_condition_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_detail.secondary_check.id"
                                @click="
                                    dataObject.other_condition_detail.secondary_check = { id: '' };
                                    dataObject.other_condition_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_condition_detail.secondary_check.id" class="ml-1">{{
                                dataObject.other_condition_detail.secondary_check.name ? dataObject.other_condition_detail.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="8">＜重複チェック＞</th>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color6">同一世帯</td>
                    <td class="bg-color6">確認項目</td>
                    <td rowspan="2">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.same_household_type"
                            :taggable="true"
                            :options="option.same_household_type"
                            @tag="addTag1($event, 'same_household_type')"
                        ></multiselect>
                    </td>
                    <td colspan="3">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.same_household_1.data"
                            :taggable="true"
                            :options="option.same_household_1"
                            @tag="addTag($event, 'same_household_1')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_household_1.first_check && dataObject.same_household_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_household_1', 'first_check')"
                            />
                            <span v-if="dataObject.same_household_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_1.first_check.id"
                                @click="
                                    dataObject.same_household_1.first_check = { id: '' };
                                    dataObject.same_household_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_household_1.first_check.id" class="ml-1">{{
                                dataObject.same_household_1.first_check.name ? dataObject.same_household_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_household_1.secondary_check && dataObject.same_household_1.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_household_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.same_household_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_1.secondary_check.id"
                                @click="
                                    dataObject.same_household_1.secondary_check = { id: '' };
                                    dataObject.same_household_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_household_1.secondary_check.id" class="ml-1">{{
                                dataObject.same_household_1.secondary_check.name ? dataObject.same_household_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6">確認条件</td>
                    <td colspan="3">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.same_household_2.data"
                            :taggable="true"
                            :options="option.same_household_2"
                            @tag="addTag($event, 'same_household_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_household_2.first_check && dataObject.same_household_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_household_2', 'first_check')"
                            />
                            <span v-if="dataObject.same_household_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_2.first_check.id"
                                @click="
                                    dataObject.same_household_2.first_check = { id: '' };
                                    dataObject.same_household_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_household_2.first_check.id" class="ml-1">{{
                                dataObject.same_household_2.first_check.name ? dataObject.same_household_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_household_2.secondary_check && dataObject.same_household_2.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_household_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.same_household_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_household_2.secondary_check.id"
                                @click="
                                    dataObject.same_household_2.secondary_check = { id: '' };
                                    dataObject.same_household_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_household_2.secondary_check.id" class="ml-1">{{
                                dataObject.same_household_2.secondary_check.name ? dataObject.same_household_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color6">同一人物</td>
                    <td class="bg-color6">確認項目</td>
                    <td rowspan="2">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.same_person_type"
                            :taggable="true"
                            :options="option.same_person_type"
                            @tag="addTag1($event, 'same_person_type')"
                        ></multiselect>
                    </td>
                    <td colspan="3">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.same_person_1.data"
                            :taggable="true"
                            :options="option.same_person_1"
                            @tag="addTag($event, 'same_person_1')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_person_1.first_check && dataObject.same_person_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_person_1', 'first_check')"
                            />
                            <span v-if="dataObject.same_person_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_1.first_check.id"
                                @click="
                                    dataObject.same_person_1.first_check = { id: '' };
                                    dataObject.same_person_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_person_1.first_check.id" class="ml-1">{{
                                dataObject.same_person_1.first_check.name ? dataObject.same_person_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_person_1.secondary_check && dataObject.same_person_1.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_person_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.same_person_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_1.secondary_check.id"
                                @click="
                                    dataObject.same_person_1.secondary_check = { id: '' };
                                    dataObject.same_person_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_person_1.secondary_check.id" class="ml-1">{{
                                dataObject.same_person_1.secondary_check.name ? dataObject.same_person_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6">確認条件</td>
                    <td colspan="3">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.same_person_2.data"
                            :taggable="true"
                            :options="option.same_person_2"
                            @tag="addTag($event, 'same_person_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_person_2.first_check && dataObject.same_person_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_person_2', 'first_check')"
                            />
                            <span v-if="dataObject.same_person_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_2.first_check.id"
                                @click="
                                    dataObject.same_person_2.first_check = { id: '' };
                                    dataObject.same_person_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_person_2.first_check.id" class="ml-1">{{
                                dataObject.same_person_2.first_check.name ? dataObject.same_person_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.same_person_2.secondary_check && dataObject.same_person_2.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'same_person_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.same_person_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.same_person_2.secondary_check.id"
                                @click="
                                    dataObject.same_person_2.secondary_check = { id: '' };
                                    dataObject.same_person_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.same_person_2.secondary_check.id" class="ml-1">{{
                                dataObject.same_person_2.secondary_check.name ? dataObject.same_person_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color6">その他</td>
                    <td class="bg-color6">確認項目</td>
                    <td rowspan="2">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.others_duplicate"
                            :taggable="true"
                            :options="option.others_duplicate"
                            @tag="addTag($event, 'others_duplicate')"
                        ></multiselect>
                    </td>
                    <td colspan="3">
                        <textarea v-model="dataObject.others_duplicate_1.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.others_duplicate_1.first_check && dataObject.others_duplicate_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'others_duplicate_1', 'first_check')"
                            />
                            <span v-if="dataObject.others_duplicate_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_1.first_check.id"
                                @click="
                                    dataObject.others_duplicate_1.first_check = { id: '' };
                                    dataObject.others_duplicate_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.others_duplicate_1.first_check.id" class="ml-1">{{
                                dataObject.others_duplicate_1.first_check.name ? dataObject.others_duplicate_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.others_duplicate_1.secondary_check && dataObject.others_duplicate_1.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'others_duplicate_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.others_duplicate_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_1.secondary_check.id"
                                @click="
                                    dataObject.others_duplicate_1.secondary_check = { id: '' };
                                    dataObject.others_duplicate_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.others_duplicate_1.secondary_check.id" class="ml-1">{{
                                dataObject.others_duplicate_1.secondary_check.name ? dataObject.others_duplicate_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6">確認条件</td>
                    <td colspan="3">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.others_duplicate_2.data"
                            :taggable="true"
                            :options="option.others_duplicate_2"
                            @tag="addTag($event, 'others_duplicate_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.others_duplicate_2.first_check && dataObject.others_duplicate_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'others_duplicate_2', 'first_check')"
                            />
                            <span v-if="dataObject.others_duplicate_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_2.first_check.id"
                                @click="
                                    dataObject.others_duplicate_2.first_check = { id: '' };
                                    dataObject.others_duplicate_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.others_duplicate_2.first_check.id" class="ml-1">{{
                                dataObject.others_duplicate_2.first_check.name ? dataObject.others_duplicate_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.others_duplicate_2.secondary_check && dataObject.others_duplicate_2.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'others_duplicate_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.others_duplicate_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.others_duplicate_2.secondary_check.id"
                                @click="
                                    dataObject.others_duplicate_2.secondary_check = { id: '' };
                                    dataObject.others_duplicate_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.others_duplicate_2.secondary_check.id" class="ml-1">{{
                                dataObject.others_duplicate_2.secondary_check.name ? dataObject.others_duplicate_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="8">＜不備チェック＞</th>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color6">名前不備確認</td>
                    <td colspan="4"><textarea v-model="dataObject.confirm_error_name.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.confirm_error_name.first_check && dataObject.confirm_error_name.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_error_name', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_error_name.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.first_check.id"
                                @click="
                                    dataObject.confirm_error_name.first_check = { id: '' };
                                    dataObject.confirm_error_name.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_error_name.first_check.id" class="ml-1">{{
                                dataObject.confirm_error_name.first_check.name ? dataObject.confirm_error_name.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.confirm_error_name.secondary_check && dataObject.confirm_error_name.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_error_name', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_error_name.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.secondary_check.id"
                                @click="
                                    dataObject.confirm_error_name.secondary_check = { id: '' };
                                    dataObject.confirm_error_name.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_error_name.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_error_name.secondary_check.name ? dataObject.confirm_error_name.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color6" rowspan="2">住所不備確認</td>
                    <td colspan="4"><textarea v-model="dataObject.address_verification_1.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.address_verification_1.first_check && dataObject.address_verification_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_1', 'first_check')"
                            />
                            <span v-if="dataObject.address_verification_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.first_check.id"
                                @click="
                                    dataObject.address_verification_1.first_check = { id: '' };
                                    dataObject.address_verification_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_1.first_check.id" class="ml-1">{{
                                dataObject.address_verification_1.first_check.name ? dataObject.address_verification_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.address_verification_1.secondary_check && dataObject.address_verification_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.address_verification_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.secondary_check.id"
                                @click="
                                    dataObject.address_verification_1.secondary_check = { id: '' };
                                    dataObject.address_verification_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_1.secondary_check.id" class="ml-1">{{
                                dataObject.address_verification_1.secondary_check.name ? dataObject.address_verification_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.address_verification_2.data"
                            :taggable="true"
                            :options="option.address_verification_2"
                            @tag="addTag($event, 'address_verification_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.address_verification_2.first_check && dataObject.address_verification_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_2', 'first_check')"
                            />
                            <span v-if="dataObject.address_verification_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.first_check.id"
                                @click="
                                    dataObject.address_verification_2.first_check = { id: '' };
                                    dataObject.address_verification_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_2.first_check.id" class="ml-1">{{
                                dataObject.address_verification_2.first_check.name ? dataObject.address_verification_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.address_verification_2.secondary_check && dataObject.address_verification_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.address_verification_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.secondary_check.id"
                                @click="
                                    dataObject.address_verification_2.secondary_check = { id: '' };
                                    dataObject.address_verification_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_2.secondary_check.id" class="ml-1">{{
                                dataObject.address_verification_2.secondary_check.name ? dataObject.address_verification_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">電話番号不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_phone_number_is_incomplete.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_phone_number_is_incomplete.first_check &&
                                dataObject.confirm_phone_number_is_incomplete.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_phone_number_is_incomplete', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.first_check.id"
                                @click="
                                    dataObject.confirm_phone_number_is_incomplete.first_check = { id: '' };
                                    dataObject.confirm_phone_number_is_incomplete.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.first_check.id" class="ml-1">{{
                                dataObject.confirm_phone_number_is_incomplete.first_check.name
                                    ? dataObject.confirm_phone_number_is_incomplete.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_phone_number_is_incomplete.secondary_check &&
                                dataObject.confirm_phone_number_is_incomplete.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_phone_number_is_incomplete', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.secondary_check.id"
                                @click="
                                    dataObject.confirm_phone_number_is_incomplete.secondary_check = { id: '' };
                                    dataObject.confirm_phone_number_is_incomplete.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_phone_number_is_incomplete.secondary_check.name
                                    ? dataObject.confirm_phone_number_is_incomplete.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">メールアドレス不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_email_address_is_incomplete.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_email_address_is_incomplete.first_check &&
                                dataObject.confirm_email_address_is_incomplete.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_email_address_is_incomplete', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.first_check.id"
                                @click="
                                    dataObject.confirm_email_address_is_incomplete.first_check = { id: '' };
                                    dataObject.confirm_email_address_is_incomplete.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.first_check.id" class="ml-1">{{
                                dataObject.confirm_email_address_is_incomplete.first_check.name
                                    ? dataObject.confirm_email_address_is_incomplete.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_email_address_is_incomplete.secondary_check &&
                                dataObject.confirm_email_address_is_incomplete.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_email_address_is_incomplete', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.secondary_check.id"
                                @click="
                                    dataObject.confirm_email_address_is_incomplete.secondary_check = { id: '' };
                                    dataObject.confirm_email_address_is_incomplete.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_email_address_is_incomplete.secondary_check.name
                                    ? dataObject.confirm_email_address_is_incomplete.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td class="bg-color6" colspan="2" rowspan="2">その他</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.other_errors.data"
                            :taggable="true"
                            :options="option.other_errors"
                            @tag="addTag($event, 'other_errors')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_errors.first_check && dataObject.other_errors.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_errors', 'first_check')"
                            />
                            <span v-if="dataObject.other_errors.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.first_check.id"
                                @click="
                                    dataObject.other_errors.first_check = { id: '' };
                                    dataObject.other_errors.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_errors.first_check.id" class="ml-1">{{
                                dataObject.other_errors.first_check.name ? dataObject.other_errors.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_errors.secondary_check && dataObject.other_errors.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_errors', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_errors.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.secondary_check.id"
                                @click="
                                    dataObject.other_errors.secondary_check = { id: '' };
                                    dataObject.other_errors.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_errors.secondary_check.id" class="ml-1">{{
                                dataObject.other_errors.secondary_check.name ? dataObject.other_errors.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.other_error_detail.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.other_error_detail.first_check && dataObject.other_error_detail.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_error_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_error_detail', 'first_check')"
                            />
                            <span v-if="dataObject.other_error_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_error_detail.first_check.id"
                                @click="
                                    dataObject.other_error_detail.first_check = { id: '' };
                                    dataObject.other_error_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_error_detail.first_check.id" class="ml-1">{{
                                dataObject.other_error_detail.first_check.name ? dataObject.other_error_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_error_detail.secondary_check && dataObject.other_error_detail.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_error_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_error_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_error_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_error_detail.secondary_check.id"
                                @click="
                                    dataObject.other_error_detail.secondary_check = { id: '' };
                                    dataObject.other_error_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_error_detail.secondary_check.id" class="ml-1">{{
                                dataObject.other_error_detail.secondary_check.name ? dataObject.other_error_detail.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="8">＜購買チェック＞</th>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color6">購入有効期間</td>
                    <td colspan="4">
                        {{ dataObject.purchase_validity_period.data }}
                        <!--                        <div class="d-flex align-items-center mb-2">-->
                        <!--                            <input-->
                        <!--                                type="text"-->
                        <!--                                v-model="dataObject.purchase_validity_period.data.start_date_y"-->
                        <!--                                style="width: 80px; padding-right: 0 !important"-->
                        <!--                                class="form-control mr-2"-->
                        <!--                            />-->
                        <!--                            年-->
                        <!--                            <input-->
                        <!--                                type="text"-->
                        <!--                                v-model="dataObject.purchase_validity_period.data.start_date_m"-->
                        <!--                                style="width: 47px; padding-right: 0 !important"-->
                        <!--                                class="form-control mx-2"-->
                        <!--                            />-->
                        <!--                            月-->
                        <!--                            <input-->
                        <!--                                type="text"-->
                        <!--                                v-model="dataObject.purchase_validity_period.data.start_date_d"-->
                        <!--                                style="width: 47px; padding-right: 0 !important"-->
                        <!--                                class="form-control mx-2"-->
                        <!--                            />-->
                        <!--                            日-->
                        <!--                            <button type="button" class="btn-sm btn btn-info mx-2 box-date">-->
                        <!--                                カレンダー-->
                        <!--                                <date-picker-->
                        <!--                                    v-model="dataObject.purchase_validity_period.data.start_date"-->
                        <!--                                    :value-type="customFormatDate"-->
                        <!--                                    :first-day-of-week="1"-->
                        <!--                                    lang="ja"-->
                        <!--                                ></date-picker>-->
                        <!--                            </button>-->
                        <!--                            から-->
                        <!--                        </div>-->
                        <!--                        <div class="d-flex align-items-center mb-2">-->
                        <!--                            <input-->
                        <!--                                type="text"-->
                        <!--                                v-model="dataObject.purchase_validity_period.data.end_date_y"-->
                        <!--                                style="width: 80px; padding-right: 0 !important"-->
                        <!--                                class="form-control mr-2"-->
                        <!--                            />-->
                        <!--                            年-->
                        <!--                            <input-->
                        <!--                                type="text"-->
                        <!--                                v-model="dataObject.purchase_validity_period.data.end_date_m"-->
                        <!--                                style="width: 47px; padding-right: 0 !important"-->
                        <!--                                class="form-control mx-2"-->
                        <!--                            />-->
                        <!--                            月-->
                        <!--                            <input-->
                        <!--                                type="text"-->
                        <!--                                v-model="dataObject.purchase_validity_period.data.end_date_d"-->
                        <!--                                style="width: 47px; padding-right: 0 !important"-->
                        <!--                                class="form-control mx-2"-->
                        <!--                            />-->
                        <!--                            日-->
                        <!--                            <button type="button" class="btn-sm btn btn-info mx-2 box-date">-->
                        <!--                                カレンダー-->
                        <!--                                <date-picker-->
                        <!--                                    v-model="dataObject.purchase_validity_period.data.end_date"-->
                        <!--                                    :value-type="customFormatDate"-->
                        <!--                                    :first-day-of-week="1"-->
                        <!--                                    lang="ja"-->
                        <!--                                ></date-picker>-->
                        <!--                            </button>-->
                        <!--                            まで-->
                        <!--                        </div>-->
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.purchase_validity_period.first_check && dataObject.purchase_validity_period.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_validity_period.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_validity_period', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_validity_period.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_validity_period.first_check.id"
                                @click="
                                    dataObject.purchase_validity_period.first_check = { id: '' };
                                    dataObject.purchase_validity_period.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_validity_period.first_check.id" class="ml-1">{{
                                dataObject.purchase_validity_period.first_check.name ? dataObject.purchase_validity_period.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.purchase_validity_period.secondary_check && dataObject.purchase_validity_period.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_validity_period.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_validity_period', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_validity_period.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_validity_period.secondary_check.id"
                                @click="
                                    dataObject.purchase_validity_period.secondary_check = { id: '' };
                                    dataObject.purchase_validity_period.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_validity_period.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_validity_period.secondary_check.name
                                    ? dataObject.purchase_validity_period.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">対象商品</td>
                    <td colspan="4"><textarea v-model="dataObject.shipping.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.shipping.first_check && dataObject.shipping.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'shipping', 'first_check')"
                            />
                            <span v-if="dataObject.shipping.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.first_check.id"
                                @click="
                                    dataObject.shipping.first_check = { id: '' };
                                    dataObject.shipping.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.shipping.first_check.id" class="ml-1">{{
                                dataObject.shipping.first_check.name ? dataObject.shipping.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.shipping.secondary_check && dataObject.shipping.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'shipping', 'secondary_check')"
                            />
                            <span v-if="dataObject.shipping.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.secondary_check.id"
                                @click="
                                    dataObject.shipping.secondary_check = { id: '' };
                                    dataObject.shipping.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.shipping.secondary_check.id" class="ml-1">{{
                                dataObject.shipping.secondary_check.name ? dataObject.shipping.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">購入条件</td>
                    <td colspan="4"><textarea v-model="dataObject.purchase_conditions.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.purchase_conditions.first_check && dataObject.purchase_conditions.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_conditions.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_conditions', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_conditions.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_conditions.first_check.id"
                                @click="
                                    dataObject.purchase_conditions.first_check = { id: '' };
                                    dataObject.purchase_conditions.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_conditions.first_check.id" class="ml-1">{{
                                dataObject.purchase_conditions.first_check.name ? dataObject.purchase_conditions.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_conditions.secondary_check && dataObject.purchase_conditions.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_conditions.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_conditions', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_conditions.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_conditions.secondary_check.id"
                                @click="
                                    dataObject.purchase_conditions.secondary_check = { id: '' };
                                    dataObject.purchase_conditions.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_conditions.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_conditions.secondary_check.name ? dataObject.purchase_conditions.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">購入店舗・流通</td>
                    <td>
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.purchase_store_or_distribution.data.select_data"
                            :taggable="true"
                            :options="option.purchase_store_or_distribution"
                            @tag="addTag($event, 'purchase_store_or_distribution')"
                        ></multiselect>
                    </td>
                    <td colspan="3">
                        <textarea v-model="dataObject.purchase_store_or_distribution.data.input_data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.purchase_store_or_distribution.first_check && dataObject.purchase_store_or_distribution.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_store_or_distribution', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.first_check.id"
                                @click="
                                    dataObject.purchase_store_or_distribution.first_check = { id: '' };
                                    dataObject.purchase_store_or_distribution.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.first_check.id" class="ml-1">{{
                                dataObject.purchase_store_or_distribution.first_check.name
                                    ? dataObject.purchase_store_or_distribution.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.purchase_store_or_distribution.secondary_check &&
                                dataObject.purchase_store_or_distribution.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_store_or_distribution', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.secondary_check.id"
                                @click="
                                    dataObject.purchase_store_or_distribution.secondary_check = { id: '' };
                                    dataObject.purchase_store_or_distribution.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_store_or_distribution.secondary_check.name
                                    ? dataObject.purchase_store_or_distribution.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2" rowspan="2">その他</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.purchase_info_other.data"
                            :taggable="true"
                            :options="option.purchase_info_other"
                            @tag="addTag($event, 'purchase_info_other')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_info_other.first_check && dataObject.purchase_info_other.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_info_other', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_info_other.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.first_check.id"
                                @click="
                                    dataObject.purchase_info_other.first_check = { id: '' };
                                    dataObject.purchase_info_other.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_info_other.first_check.id" class="ml-1">{{
                                dataObject.purchase_info_other.first_check.name ? dataObject.purchase_info_other.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_info_other.secondary_check && dataObject.purchase_info_other.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_info_other', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_info_other.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.secondary_check.id"
                                @click="
                                    dataObject.purchase_info_other.secondary_check = { id: '' };
                                    dataObject.purchase_info_other.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_info_other.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_info_other.secondary_check.name ? dataObject.purchase_info_other.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.purchase_info_other_detail.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.purchase_info_other_detail.first_check && dataObject.purchase_info_other_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_info_other_detail', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_info_other_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other_detail.first_check.id"
                                @click="
                                    dataObject.purchase_info_other_detail.first_check = { id: '' };
                                    dataObject.purchase_info_other_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_info_other_detail.first_check.id" class="ml-1">{{
                                dataObject.purchase_info_other_detail.first_check.name ? dataObject.purchase_info_other_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.purchase_info_other_detail.secondary_check && dataObject.purchase_info_other_detail.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_info_other_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_info_other_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other_detail.secondary_check.id"
                                @click="
                                    dataObject.purchase_info_other_detail.secondary_check = { id: '' };
                                    dataObject.purchase_info_other_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_info_other_detail.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_info_other_detail.secondary_check.name
                                    ? dataObject.purchase_info_other_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
// import DatePicker from "vue2-datepicker";

export default {
    name: 'Tab10Require',
    props: {
        dataObject: {
            type: Object,
            required: false
        },
        currentUser: {
            type: Object,
            required: false
        },
        detailBeginForDocument: {
            type: Object,
            required: false
        }
    },
    components: { Multiselect },
    data() {
        return {
            customFormatDate: 'YYYY-MM-DD',
            option: {
                lottery_method: ['データ抽選', 'ハガキ引き抜き'],
                prize_winning: ['重複確認する', '重複確認しない '],
                cross_application_form: ['重複確認する', '重複確認しない'],
                cross_distribution: ['重複確認する', '重複確認しない'],
                ratio_specification: ['なし', '賞品', '応募形態', '口数', '流通・店舗', '都道府県', '年齢', '性別', 'アンケート回答', 'その他'],
                other_conditions: ['あり', 'なし'],
                same_household_1: ['住所', '住所＋苗字', '住所＋電話番号'],
                same_household_2: ['完全一致', '近似値目チェック'],
                same_household_type: ['あり', 'なし'],
                same_person_1: ['名前', '住所', '電話番号', 'メールアドレス', 'アカウント・ID'],
                same_person_2: ['完全一致', '近似値目チェック'],
                same_person_type: ['あり', 'なし'],
                others_duplicate_2: ['完全一致', '近似値目チェック'],
                others_duplicate: ['あり', 'なし'],
                address_verification_2: ['システムチェック', '近似値目チェック'],
                other_errors: ['あり', 'なし'],
                purchase_store_or_distribution: ['あり', 'なし'],
                purchase_info_other: ['あり', 'なし']
            }
        };
    },
    methods: {
        renderDate(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                return `${year} 年 ${month} 月 ${day} 日`;
            }
            return '';
        },
        renderDate1(time) {
            const daysInJapanese = ['日', '月', '火', '水', '木', '金', '土'];
            const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = addLeadingZero(date.getHours());
                const minutes = addLeadingZero(date.getMinutes());
                const dayOfWeek = date.getDay();
                const dayNameInJapanese = daysInJapanese[dayOfWeek];
                return `${year} 年 ${month} 月 ${day} 日（${dayNameInJapanese}）${hours}：${minutes}`;
            }
            return '';
        },
        addTag(newTag, key) {
            console.log(key);
            this.option[key].push(newTag);
            this.dataObject[key].data.push(newTag);
        },
        addTag1(newTag, key) {
            console.log(key);
            this.option[key].push(newTag);
            this.dataObject[key].push(newTag);
        },
        updateCheckbox(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject[key][key2].id = value;
            } else {
                this.dataObject[key][key2].id = null;
            }
        },
        updateCheckbox1(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject.prizes.data[key][key2].id = value;
            } else {
                this.dataObject.prizes.data[key][key2].id = null;
            }
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
