import i18n from '@/i18n';

import { SOCIAL_MEDIA_LOGIN } from '../../constants';

const configFormBasic = () => {
    return {
        configFieldSelectCampaign: {
            trackBy: 'id',
            label: 'name',
            errorField: i18n.t('pageReturnPrize.nameCampaign'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldSelectClient: {
            trackBy: 'id',
            label: 'name',
            errorField: i18n.t('pageReturnPrize.nameClient'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: []
        },
        configFieldNameForm: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.nameForm'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldPublicPath: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.publicPath'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255', 'sub-domain']
        },

        configFieldLimitNumber: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.limitNumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max10']
        },
        configFieldRedirectURL: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.redirectURL'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255', 'sub-domain']
        },
        configFieldSendMailed: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.sendMailed'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255', 'email']
        },
        configFieldUrlPublicReservation: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.urlPublicReservation'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldUrlAfterPublicationEnd: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.urlAfterPublicationEnd'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldTag: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.tag'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldDateOfExpiry: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingBasic.dateOfExpiry'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },

        validateArrayURL: [
            {
                configFieldUrl: {
                    isRequired: false,
                    isNumber: false,
                    isMaxLength: false,
                    error: false,
                    errorField: i18n.t('managerForm.settingBasic.urlParam'),
                    errorCustomMess: '',
                    valueSync: '',
                    typesValidate: ['required', 'max255', 'sub-domain']
                }
            }
        ],

        validateArraySocial: SOCIAL_MEDIA_LOGIN.map((o) => {
            return {
                configField: {
                    isNumber: false,
                    isMaxLength: false,
                    error: false,
                    errorField: o.name,
                    errorCustomMess: '',
                    valueSync: o?.value ? o.value : '',
                    typesValidate: ['max255']
                }
            };
        })
    };
};

export { configFormBasic };
