<template>
    <div>
        <ModalCommon id="ModalAddPage" :config="configModal" @save="handleSave()" @close="handleClose()">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.titlePage') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <InputText :model.sync="name_page" :config.sync="configFieldNamePage" @blur="handleValidateField(configFieldNamePage)" />
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';

import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';

export default {
    name: 'ModalAddPage',
    components: {
        ModalCommon,
        InputText
    },
    props: {
        configModal: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            name_page: '',
            configFieldNamePage: {
                isNumber: false,
                isMaxLength: false,
                error: false,
                errorField: this.$t('managerForm.settingPage.titlePage'),
                errorCustomMess: '',
                valueSync: '',
                typesValidate: ['required', 'max255']
            }
        };
    },
    methods: {
        handleSave() {
            let check = validateField(this.configFieldNamePage);
            if (!check) return;
            this.configModal.pageAdd.push({
                name_page: this.name_page,
                isDefault: true
            });
            this.name_page = '';
            this.configModal.model = false;
        },

        handleClose() {
            this.name_page = '';
            this.configModal.model = false;
            this.configFieldNamePage.error = false;
        },

        handleValidateField(configField) {
            validateField(configField);
        }
    }
};
</script>

<style lang="scss" scoped></style>
