<template>
    <div>
        <div class="col-lg-12">
            <div class="form-group row mb-3">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingSerialCode.serialCode') }}</label>
                <div class="col-sm-9">
                    <UploadFileCommon @dataFile="handleSerialCode" title="ファイルアップロード" :id="`SerialCode`" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingSerialCode.registerSerialCode') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <div class="d-flex flex-row flex-wrap">
                        <div class="flex-fill mb-3 mr-3">
                            <InputText
                                style="min-width: 150px"
                                :model.sync="formInput.seri_code"
                                :config.sync="configForm.configFieldSerialCode"
                                @blur="handleValidateField(configForm.configFieldSerialCode)"
                            />
                        </div>
                        <div class="row flex-fill mb-3">
                            <label class="col-sm-2 text-nowrap" style="min-width: 50px">{{ $t('managerForm.settingSerialCode.numberLimit') }}</label>
                            <div class="col-sm-9">
                                <InputText
                                    style="min-width: 150px"
                                    :model.sync="formInput.number_limit"
                                    :config.sync="configForm.configFieldNumberLimit"
                                    @blur="handleValidateField(configForm.configFieldNumberLimit)"
                                />
                            </div>
                        </div>
                        <div class="flex-fill text-center">
                            <button type="submit" @click="handleRegister()" class="btn btn-success">{{ $t('btn.register') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: fit-content">{{
                    $t('managerForm.settingSerialCode.applicationCommonMail')
                }}</label>
                <div class="col-sm-9">
                    <InputCheckBox class="mr-4 py-1" :model.sync="dataForm.mail_common" :id="`check_mail-common`" :value="false" :label="``" />
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered custom__table--sticky table-box">
                    <thead>
                        <tr>
                            <th v-for="(item, k) in configTable.headers" :key="k">
                                <div class="d-flex align-items-center">
                                    {{ item.label }}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(seriCode, idx) in dataForm.serial_codes" :key="idx">
                            <td>
                                <div class="row-data-small" v-b-tooltip.hover :title="seriCode?.seri_code">
                                    {{ seriCode?.seri_code ?? '' }}
                                </div>
                            </td>
                            <td>
                                <div class="row-data-small" v-b-tooltip.hover :title="seriCode?.number_limit">
                                    {{ seriCode?.number_limit ?? '' }}
                                </div>
                            </td>
                            <td>
                                <div class="row-data-small" v-b-tooltip.hover :title="seriCode?.number_used">
                                    {{ seriCode?.number_used ?? '' }}
                                </div>
                            </td>
                            <td>
                                <div class="row-data-small btn">
                                    <i @click="handleDeleteSerialCode(seriCode, idx)" class="fa fa-trash text-danger"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import UploadFileCommon from '@/components/Uploads/Files/main.vue';

import { InputText, InputCheckBox } from '@/components/Input';
import { configTable } from './constants';
import { validateField, validateAllField } from '@/utils/validate';

export default {
    name: 'SettingSerialCode',
    components: {
        UploadFileCommon,
        InputText,
        InputCheckBox
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            formInput: {
                seri_code: '',
                number_limit: ''
            },
            configTable: configTable,
            initForm: this.dataForm
        };
    },
    methods: {
        handleSerialCode(file) {
            this.formData = file;
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleRegister() {
            let check = validateAllField(this.configForm);
            if (!check) return;
            this.initForm.serial_codes.push({
                seri_code: this.formInput.seri_code,
                number_limit: this.formInput.number_limit,
                number_used: 0
            });
            this.formInput = {
                seri_code: '',
                number_limit: ''
            };
        },

        handleDeleteSerialCode(obj) {
            this.initForm.serial_codes = this.initForm.serial_codes.filter((o) => o.seri_code !== obj.seri_code);
        }
    }
};
</script>

<style lang="scss" scoped></style>
