<template>
    <div class="table-responsive">
        <table class="table-bordered table">
            <tbody>
                <tr>
                    <th colspan="4">＜CP概要＞</th>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン名</td>
                    <td colspan="3">{{ detailBeginForDocument.name }}</td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">顧客様情報</td>
                    <td colspan="3">
                        {{ detailBeginForDocument.clients_formatted
                        }}{{ detailBeginForDocument.agencies_formatted ? '/' + detailBeginForDocument.agencies_formatted : '' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン期間</td>
                    <td colspan="3">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date)
                        }}{{ detailBeginForDocument.campaign_end_date ? '～' + renderDate1(detailBeginForDocument.campaign_end_date) : '' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">CPサイト・CPツイート</td>
                    <td colspan="3">{{ detailBeginForDocument.url }}</td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">ディレクション担当</td>
                    <td colspan="3">
                        {{ detailBeginForDocument.person_in_charge ? detailBeginForDocument.person_in_charge.name : '' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">営業担当</td>
                    <td colspan="3">{{ detailBeginForDocument.sale_staff ? detailBeginForDocument.sale_staff.name : '' }}</td>
                </tr>

                <tr>
                    <th colspan="2">■CP概要</th>
                    <th>1次チェック</th>
                    <th>2次チェック</th>
                </tr>
                <template v-for="(item, index) in dataObject.overview_campaign">
                    <tr :key="index + 'a'">
                        <td class="bg-color1">SNS</td>
                        <td>
                            <textarea v-model="item.sns.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="item.sns.first_check && item.sns.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.sns.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'sns', 'first_check')"
                                />
                                <span v-if="item.sns.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.sns.first_check.id"
                                    @click="
                                        item.sns.first_check = { id: '' };
                                        item.sns.first_check.change = true;
                                    "
                                />
                                <span v-if="item.sns.first_check.id" class="ml-1">{{
                                    item.sns.first_check.name ? item.sns.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="item.sns.secondary_check && item.sns.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.sns.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'sns', 'secondary_check')"
                                />
                                <span v-if="item.sns.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.sns.secondary_check.id"
                                    @click="
                                        item.sns.secondary_check = { id: '' };
                                        item.sns.secondary_check.change = true;
                                    "
                                />
                                <span v-if="item.sns.secondary_check.id" class="ml-1">{{
                                    item.sns.secondary_check.name ? item.sns.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr :key="index + 'b'">
                        <td class="bg-color1">収集ツール</td>
                        <td>
                            <textarea v-model="item.collection_tools.data" class="form-control" />
                            <!--                            <multiselect-->
                            <!--                                :multiple="true"-->
                            <!--                                :showPointer="false"-->
                            <!--                                :showNoResults="false"-->
                            <!--                                selectedLabel=""-->
                            <!--                                deselectLabel=""-->
                            <!--                                placeholder=""-->
                            <!--                                selectLabel=""-->
                            <!--                                :showNoOptions="false"-->
                            <!--                                v-model="item.collection_tools.data"-->
                            <!--                                :taggable="true"-->
                            <!--                                :options="option.collection_tools"-->
                            <!--                                @tag="addTag($event, 'collection_tools')"-->
                            <!--                            ></multiselect>-->
                        </td>
                        <td>
                            <label for="" v-if="item.collection_tools.first_check && item.collection_tools.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.collection_tools.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'collection_tools', 'first_check')"
                                />
                                <span v-if="item.collection_tools.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.collection_tools.first_check.id"
                                    @click="
                                        item.collection_tools.first_check = { id: '' };
                                        item.collection_tools.first_check.change = true;
                                    "
                                />
                                <span v-if="item.collection_tools.first_check.id" class="ml-1">{{
                                    item.collection_tools.first_check.name ? item.collection_tools.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="item.collection_tools.secondary_check && item.collection_tools.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.collection_tools.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'collection_tools', 'secondary_check')"
                                />
                                <span v-if="item.collection_tools.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.collection_tools.secondary_check.id"
                                    @click="
                                        item.collection_tools.secondary_check = { id: '' };
                                        item.collection_tools.secondary_check.change = true;
                                    "
                                />
                                <span v-if="item.collection_tools.secondary_check.id" class="ml-1">{{
                                    item.collection_tools.secondary_check.name ? item.collection_tools.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr v-if="item.campaign_name_atelu_only.data" :key="index + 'c'">
                        <td class="bg-color1">キャンペーン名（ATELUのみ）</td>
                        <td>
                            <textarea v-model="item.campaign_name_atelu_only.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="item.campaign_name_atelu_only.first_check && item.campaign_name_atelu_only.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.campaign_name_atelu_only.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'campaign_name_atelu_only', 'first_check')"
                                />
                                <span v-if="item.campaign_name_atelu_only.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.campaign_name_atelu_only.first_check.id"
                                    @click="
                                        item.campaign_name_atelu_only.first_check = { id: '' };
                                        item.campaign_name_atelu_only.first_check.change = true;
                                    "
                                />
                                <span v-if="item.campaign_name_atelu_only.first_check.id" class="ml-1">{{
                                    item.campaign_name_atelu_only.first_check.name ? item.campaign_name_atelu_only.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="item.campaign_name_atelu_only.secondary_check && item.campaign_name_atelu_only.secondary_check.change"
                            >
                                <input
                                    type="checkbox"
                                    :checked="item.campaign_name_atelu_only.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'campaign_name_atelu_only', 'secondary_check')"
                                />
                                <span v-if="item.campaign_name_atelu_only.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.campaign_name_atelu_only.secondary_check.id"
                                    @click="
                                        item.campaign_name_atelu_only.secondary_check = { id: '' };
                                        item.campaign_name_atelu_only.secondary_check.change = true;
                                    "
                                />
                                <span v-if="item.campaign_name_atelu_only.secondary_check.id" class="ml-1">{{
                                    item.campaign_name_atelu_only.secondary_check.name ? item.campaign_name_atelu_only.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr :key="index + 'd'">
                        <td class="bg-color1">収集対象タグ</td>
                        <td>
                            <textarea v-model="item.tag_collected.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="item.tag_collected.first_check && item.tag_collected.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.tag_collected.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'tag_collected', 'first_check')"
                                />
                                <span v-if="item.tag_collected.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.tag_collected.first_check.id"
                                    @click="
                                        item.tag_collected.first_check = { id: '' };
                                        item.tag_collected.first_check.change = true;
                                    "
                                />
                                <span v-if="item.tag_collected.first_check.id" class="ml-1">{{
                                    item.tag_collected.first_check.name ? item.tag_collected.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="item.tag_collected.secondary_check && item.tag_collected.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.tag_collected.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, index, 'tag_collected', 'secondary_check')"
                                />
                                <span v-if="item.tag_collected.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.tag_collected.secondary_check.id"
                                    @click="
                                        item.tag_collected.secondary_check = { id: '' };
                                        item.tag_collected.secondary_check.change = true;
                                    "
                                />
                                <span v-if="item.tag_collected.secondary_check.id" class="ml-1">{{
                                    item.tag_collected.secondary_check.name ? item.tag_collected.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <tr>
                    <th colspan="4">■応募数報告</th>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color1">報告スケジュール</td>
                    <td>
                        {{ dataObject.reporting_schedule.data }}
                        <!--                        <multiselect-->
                        <!--                            :multiple="true"-->
                        <!--                            :showPointer="false"-->
                        <!--                            :showNoResults="false"-->
                        <!--                            selectedLabel=""-->
                        <!--                            deselectLabel=""-->
                        <!--                            placeholder=""-->
                        <!--                            selectLabel=""-->
                        <!--                            :showNoOptions="false"-->
                        <!--                            v-model="dataObject.reporting_schedule.data"-->
                        <!--                            :taggable="true"-->
                        <!--                            :options="option.reporting_schedule"-->
                        <!--                            @tag="addTag($event, 'reporting_schedule')"-->
                        <!--                        ></multiselect>-->
                    </td>
                    <td>
                        <label for="" v-if="dataObject.reporting_schedule.first_check && dataObject.reporting_schedule.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule', 'first_check')"
                            />
                            <span v-if="dataObject.reporting_schedule.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.first_check.id"
                                @click="
                                    dataObject.reporting_schedule.first_check = { id: '' };
                                    dataObject.reporting_schedule.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule.first_check.id" class="ml-1">{{
                                dataObject.reporting_schedule.first_check.name ? dataObject.reporting_schedule.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.reporting_schedule.secondary_check && dataObject.reporting_schedule.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule', 'secondary_check')"
                            />
                            <span v-if="dataObject.reporting_schedule.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule.secondary_check.id"
                                @click="
                                    dataObject.reporting_schedule.secondary_check = { id: '' };
                                    dataObject.reporting_schedule.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule.secondary_check.id" class="ml-1">{{
                                dataObject.reporting_schedule.secondary_check.name ? dataObject.reporting_schedule.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.reporting_schedule_detail.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.reporting_schedule_detail.first_check && dataObject.reporting_schedule_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule_detail', 'first_check')"
                            />
                            <span v-if="dataObject.reporting_schedule_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.first_check.id"
                                @click="
                                    dataObject.reporting_schedule_detail.first_check = { id: '' };
                                    dataObject.reporting_schedule_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule_detail.first_check.id" class="ml-1">{{
                                dataObject.reporting_schedule_detail.first_check.name ? dataObject.reporting_schedule_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.reporting_schedule_detail.secondary_check && dataObject.reporting_schedule_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'reporting_schedule_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.reporting_schedule_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.reporting_schedule_detail.secondary_check.id"
                                @click="
                                    dataObject.reporting_schedule_detail.secondary_check = { id: '' };
                                    dataObject.reporting_schedule_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.reporting_schedule_detail.secondary_check.id" class="ml-1">{{
                                dataObject.reporting_schedule_detail.secondary_check.name
                                    ? dataObject.reporting_schedule_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="3" class="bg-color1">報告内容</td>
                    <td>
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.contents_of_report.data"
                            :taggable="true"
                            :options="option.contents_of_report"
                            @tag="addTag($event, 'contents_of_report')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.contents_of_report.first_check && dataObject.contents_of_report.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report', 'first_check')"
                            />
                            <span v-if="dataObject.contents_of_report.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.first_check.id"
                                @click="
                                    dataObject.contents_of_report.first_check = { id: '' };
                                    dataObject.contents_of_report.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report.first_check.id" class="ml-1">{{
                                dataObject.contents_of_report.first_check.name ? dataObject.contents_of_report.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.contents_of_report.secondary_check && dataObject.contents_of_report.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report', 'secondary_check')"
                            />
                            <span v-if="dataObject.contents_of_report.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report.secondary_check.id"
                                @click="
                                    dataObject.contents_of_report.secondary_check = { id: '' };
                                    dataObject.contents_of_report.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report.secondary_check.id" class="ml-1">{{
                                dataObject.contents_of_report.secondary_check.name ? dataObject.contents_of_report.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.contents_of_report_twitter_rt.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.contents_of_report_twitter_rt.first_check && dataObject.contents_of_report_twitter_rt.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_twitter_rt.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_twitter_rt', 'first_check')"
                            />
                            <span v-if="dataObject.contents_of_report_twitter_rt.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_twitter_rt.first_check.id"
                                @click="
                                    dataObject.contents_of_report_twitter_rt.first_check = { id: '' };
                                    dataObject.contents_of_report_twitter_rt.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_twitter_rt.first_check.id" class="ml-1">{{
                                dataObject.contents_of_report_twitter_rt.first_check.name
                                    ? dataObject.contents_of_report_twitter_rt.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.contents_of_report_twitter_rt.secondary_check &&
                                dataObject.contents_of_report_twitter_rt.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_twitter_rt.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_twitter_rt', 'secondary_check')"
                            />
                            <span v-if="dataObject.contents_of_report_twitter_rt.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_twitter_rt.secondary_check.id"
                                @click="
                                    dataObject.contents_of_report_twitter_rt.secondary_check = { id: '' };
                                    dataObject.contents_of_report_twitter_rt.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_twitter_rt.secondary_check.id" class="ml-1">{{
                                dataObject.contents_of_report_twitter_rt.secondary_check.name
                                    ? dataObject.contents_of_report_twitter_rt.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.contents_of_report_detail.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.contents_of_report_detail.first_check && dataObject.contents_of_report_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_detail', 'first_check')"
                            />
                            <span v-if="dataObject.contents_of_report_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.first_check.id"
                                @click="
                                    dataObject.contents_of_report_detail.first_check = { id: '' };
                                    dataObject.contents_of_report_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_detail.first_check.id" class="ml-1">{{
                                dataObject.contents_of_report_detail.first_check.name ? dataObject.contents_of_report_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.contents_of_report_detail.secondary_check && dataObject.contents_of_report_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'contents_of_report_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.contents_of_report_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.contents_of_report_detail.secondary_check.id"
                                @click="
                                    dataObject.contents_of_report_detail.secondary_check = { id: '' };
                                    dataObject.contents_of_report_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.contents_of_report_detail.secondary_check.id" class="ml-1">{{
                                dataObject.contents_of_report_detail.secondary_check.name
                                    ? dataObject.contents_of_report_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color1">収集対象投稿(URL)</td>
                    <td>
                        <textarea v-model="dataObject.post_to_be_collected_word.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.post_to_be_collected_word.first_check && dataObject.post_to_be_collected_word.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_word.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'post_to_be_collected_word', 'first_check')"
                            />
                            <span v-if="dataObject.post_to_be_collected_word.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_word.first_check.id"
                                @click="
                                    dataObject.post_to_be_collected_word.first_check = { id: '' };
                                    dataObject.post_to_be_collected_word.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.post_to_be_collected_word.first_check.id" class="ml-1">{{
                                dataObject.post_to_be_collected_word.first_check.name ? dataObject.post_to_be_collected_word.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.post_to_be_collected_word.secondary_check && dataObject.post_to_be_collected_word.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_word.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'post_to_be_collected_word', 'secondary_check')"
                            />
                            <span v-if="dataObject.post_to_be_collected_word.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_word.secondary_check.id"
                                @click="
                                    dataObject.post_to_be_collected_word.secondary_check = { id: '' };
                                    dataObject.post_to_be_collected_word.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.post_to_be_collected_word.secondary_check.id" class="ml-1">{{
                                dataObject.post_to_be_collected_word.secondary_check.name
                                    ? dataObject.post_to_be_collected_word.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.post_to_be_collected_url.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.post_to_be_collected_url.first_check && dataObject.post_to_be_collected_url.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_url.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'post_to_be_collected_url', 'first_check')"
                            />
                            <span v-if="dataObject.post_to_be_collected_url.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_url.first_check.id"
                                @click="
                                    dataObject.post_to_be_collected_url.first_check = { id: '' };
                                    dataObject.post_to_be_collected_url.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.post_to_be_collected_url.first_check.id" class="ml-1">{{
                                dataObject.post_to_be_collected_url.first_check.name ? dataObject.post_to_be_collected_url.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.post_to_be_collected_url.secondary_check && dataObject.post_to_be_collected_url.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_url.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'post_to_be_collected_url', 'secondary_check')"
                            />
                            <span v-if="dataObject.post_to_be_collected_url.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.post_to_be_collected_url.secondary_check.id"
                                @click="
                                    dataObject.post_to_be_collected_url.secondary_check = { id: '' };
                                    dataObject.post_to_be_collected_url.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.post_to_be_collected_url.secondary_check.id" class="ml-1">{{
                                dataObject.post_to_be_collected_url.secondary_check.name
                                    ? dataObject.post_to_be_collected_url.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="3" class="bg-color1">テスト応募</td>
                    <td>
                        <textarea v-model="dataObject.test_application_1.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.test_application_1.first_check && dataObject.test_application_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'test_application_1', 'first_check')"
                            />
                            <span v-if="dataObject.test_application_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_1.first_check.id"
                                @click="
                                    dataObject.test_application_1.first_check = { id: '' };
                                    dataObject.test_application_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.test_application_1.first_check.id" class="ml-1">{{
                                dataObject.test_application_1.first_check.name ? dataObject.test_application_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.test_application_1.secondary_check && dataObject.test_application_1.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'test_application_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.test_application_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_1.secondary_check.id"
                                @click="
                                    dataObject.test_application_1.secondary_check = { id: '' };
                                    dataObject.test_application_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.test_application_1.secondary_check.id" class="ml-1">{{
                                dataObject.test_application_1.secondary_check.name ? dataObject.test_application_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.test_application_2.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.test_application_2.first_check && dataObject.test_application_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'test_application_2', 'first_check')"
                            />
                            <span v-if="dataObject.test_application_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_2.first_check.id"
                                @click="
                                    dataObject.test_application_2.first_check = { id: '' };
                                    dataObject.test_application_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.test_application_2.first_check.id" class="ml-1">{{
                                dataObject.test_application_2.first_check.name ? dataObject.test_application_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.test_application_2.secondary_check && dataObject.test_application_2.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'test_application_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.test_application_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_2.secondary_check.id"
                                @click="
                                    dataObject.test_application_2.secondary_check = { id: '' };
                                    dataObject.test_application_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.test_application_2.secondary_check.id" class="ml-1">{{
                                dataObject.test_application_2.secondary_check.name ? dataObject.test_application_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea v-model="dataObject.test_application_3.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.test_application_3.first_check && dataObject.test_application_3.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_3.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'test_application_3', 'first_check')"
                            />
                            <span v-if="dataObject.test_application_3.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_3.first_check.id"
                                @click="
                                    dataObject.test_application_3.first_check = { id: '' };
                                    dataObject.test_application_3.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.test_application_3.first_check.id" class="ml-1">{{
                                dataObject.test_application_3.first_check.name ? dataObject.test_application_3.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.test_application_3.secondary_check && dataObject.test_application_3.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_3.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'test_application_3', 'secondary_check')"
                            />
                            <span v-if="dataObject.test_application_3.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.test_application_3.secondary_check.id"
                                @click="
                                    dataObject.test_application_3.secondary_check = { id: '' };
                                    dataObject.test_application_3.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.test_application_3.secondary_check.id" class="ml-1">{{
                                dataObject.test_application_3.secondary_check.name ? dataObject.test_application_3.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="4">■その他(特記)</th>
                </tr>
                <tr>
                    <td class="bg-color1">無効内容の詳細</td>
                    <td>
                        <textarea v-model="dataObject.invalid_content.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.invalid_content.first_check && dataObject.invalid_content.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'invalid_content', 'first_check')"
                            />
                            <span v-if="dataObject.invalid_content.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.first_check.id"
                                @click="
                                    dataObject.invalid_content.first_check = { id: '' };
                                    dataObject.invalid_content.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.invalid_content.first_check.id" class="ml-1">{{
                                dataObject.invalid_content.first_check.name ? dataObject.invalid_content.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.invalid_content.secondary_check && dataObject.invalid_content.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'invalid_content', 'secondary_check')"
                            />
                            <span v-if="dataObject.invalid_content.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.invalid_content.secondary_check.id"
                                @click="
                                    dataObject.invalid_content.secondary_check = { id: '' };
                                    dataObject.invalid_content.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.invalid_content.secondary_check.id" class="ml-1">{{
                                dataObject.invalid_content.secondary_check.name ? dataObject.invalid_content.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color1">その他連絡事項</td>
                    <td>
                        <textarea v-model="dataObject.other_information.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_information.first_check && dataObject.other_information.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_information', 'first_check')"
                            />
                            <span v-if="dataObject.other_information.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.first_check.id"
                                @click="
                                    dataObject.other_information.first_check = { id: '' };
                                    dataObject.other_information.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_information.first_check.id" class="ml-1">{{
                                dataObject.other_information.first_check.name ? dataObject.other_information.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_information.secondary_check && dataObject.other_information.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_information', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_information.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_information.secondary_check.id"
                                @click="
                                    dataObject.other_information.secondary_check = { id: '' };
                                    dataObject.other_information.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_information.secondary_check.id" class="ml-1">{{
                                dataObject.other_information.secondary_check.name ? dataObject.other_information.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color1">ディレクション確認内容</td>
                    <td>
                        <textarea v-model="dataObject.confirm_detail_direction.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.confirm_detail_direction.first_check && dataObject.confirm_detail_direction.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_detail_direction.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_detail_direction', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_detail_direction.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_detail_direction.first_check.id"
                                @click="
                                    dataObject.confirm_detail_direction.first_check = { id: '' };
                                    dataObject.confirm_detail_direction.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_detail_direction.first_check.id" class="ml-1">{{
                                dataObject.confirm_detail_direction.first_check.name ? dataObject.confirm_detail_direction.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.confirm_detail_direction.secondary_check && dataObject.confirm_detail_direction.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_detail_direction.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_detail_direction', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_detail_direction.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_detail_direction.secondary_check.id"
                                @click="
                                    dataObject.confirm_detail_direction.secondary_check = { id: '' };
                                    dataObject.confirm_detail_direction.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_detail_direction.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_detail_direction.secondary_check.name
                                    ? dataObject.confirm_detail_direction.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'Tab4Require',
    props: {
        dataObject: {
            type: Object,
            required: false
        },
        currentUser: {
            type: Object,
            required: false
        },
        detailBeginForDocument: {
            type: Object,
            required: false
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            option: {
                collection_tools: ['キャンつく', 'ATERU', 'クライアント支給ツール'],
                reporting_schedule: ['週報', '月報', '最終一括', 'その他イレギュラー'],
                contents_of_report: ['日別応募件数', '週別応募件数', '対象投稿別応募件数']
            }
        };
    },
    methods: {
        renderDate(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                return `${year} 年 ${month} 月 ${day} 日`;
            }
            return '';
        },
        renderDate1(time) {
            const daysInJapanese = ['日', '月', '火', '水', '木', '金', '土'];
            const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = addLeadingZero(date.getHours());
                const minutes = addLeadingZero(date.getMinutes());
                const dayOfWeek = date.getDay();
                const dayNameInJapanese = daysInJapanese[dayOfWeek];
                return `${year} 年 ${month} 月 ${day} 日（${dayNameInJapanese}）${hours}：${minutes}`;
            }
            return '';
        },
        addTag(newTag, key) {
            console.log(key);
            this.option[key].push(newTag);
            this.dataObject[key].data.push(newTag);
        },
        updateCheckbox(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject[key][key2].id = value;
            } else {
                this.dataObject[key][key2].id = null;
            }
        },
        updateCheckbox1(event, value, key, key1, key2) {
            if (event.target.checked) {
                this.dataObject.overview_campaign[key][key1][key2].id = value;
            } else {
                this.dataObject.overview_campaign[key][key1][key2].id = null;
            }
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped>
.box8 input[type='text'] {
    width: 200px;
}

.box8 textarea.form-control {
    //width: 400px;
}
</style>
