<template>
    <div>
        <quillEditor
            :id="id"
            class="box__quill--editor"
            :class="`${config?.error ? 'is-invalid' : ''}`"
            v-model="dataModel"
            :ref="refQuill"
            :options="config.editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
        >
        </quillEditor>
        <div class="invalid-feedback d-block">
            <slot />
        </div>
    </div>
</template>

<script>
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

export default {
    name: 'QuillEditorComponent',
    components: {
        quillEditor
    },
    props: {
        id: {
            type: String,
            default: 'quilleditor'
        },
        refQuill: {
            type: String,
            default: 'myQuillEditor'
        },
        model: {
            type: String,
            required: true,
            default: ''
        },
        config: {
            type: Object,
            default: () => {
                return {
                    editorOption: {
                        placeholder: ''
                    },
                    valueSync: '',
                    error: true
                };
            }
        }
    },
    data() {
        return {
            dataModel: ''
        };
    },
    computed: {
        editor() {
            return this.refQuill.quill;
        }
    },
    methods: {
        onEditorBlur(quill) {
            this.$emit('blur', quill);
        },
        onEditorFocus(quill) {
            this.$emit('focus', quill);
        },
        onEditorReady(quill) {
            this.$emit('ready', quill);
        },
        onEditorChange({ quill, html, text }) {
            this.dataModel = html;
            this.$emit('change', { quill, html, text });
        }
    },
    watch: {
        model: {
            handler: function (newModel) {
                this.dataModel = newModel;
            },
            immediate: true
        },

        config: {
            handler: function (newConfig) {
                this.$emit('update:config', newConfig);
            },
            immediate: true,
            deep: true
        },

        dataModel: {
            handler: function () {
                let config = this.config;
                config.valueSync = this.dataModel;
                config.error = false;

                this.$emit('update:config', config);
                this.$emit('update:model', this.dataModel);
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.box__quill--editor {
    & .ql-container.ql-snow {
        & .ql-editor {
            font-size: 14px;
            min-height: 250px;
        }
    }
    &.is-invalid {
        border: 1px solid;
        border-color: #ff3d60;
    }
}
</style>
