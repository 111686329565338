<template>
    <div>
        <div class="col-lg-12">
            <b-tabs justified active-nav-item-class="font-weight-bold" content-class="p-3 text-muted">
                <b-tab v-for="(tab, index) in tabDesign" :key="index" class="border-0">
                    <template v-slot:title>
                        <span class="">{{ tab.name }}</span>
                    </template>
                    <div>
                        <div v-if="tab.id == 1">
                            <TemplateDesign />
                        </div>
                        <div v-if="tab.id == 2">
                            <form class="needs-validation" autocomplete="off">
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDesign.header') }} </label>
                                    <div class="col-sm-9">
                                        <QuillEditor :id="`form-header`" :refQuill="`dataForm_header`" :model.sync="dataForm.header" />
                                        <!-- <Editor
                                            :id="`dataForm_header`"
                                            :model.sync="dataForm.header"
                                            :modelEvents="`change keydown blur focus paste`"
                                        /> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDesign.footer') }} </label>
                                    <div class="col-sm-9">
                                        <QuillEditor :id="`form-footer`" :refQuill="`dataForm_footer`" :model.sync="dataForm.footer" />
                                        <!-- <Editor
                                            :id="`dataForm_footer`"
                                            :model.sync="dataForm.footer"
                                            :modelEvents="`change keydown blur focus paste`"
                                        /> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDesign.GA') }} </label>
                                    <div class="col-sm-9">
                                        <textarea rows="3" v-model="dataForm.ga" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDesign.title') }} </label>
                                    <div class="col-sm-9">
                                        <textarea rows="3" v-model="dataForm.title" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                        >{{ $t('managerForm.settingDesign.description') }}
                                    </label>
                                    <div class="col-sm-9">
                                        <textarea rows="3" v-model="dataForm.description" class="form-control"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import { validateField } from '@/utils/validate';

import TemplateDesign from './template.vue';
import QuillEditor from '@/components/QuillEditor/main.vue';
// import Editor from '@/components/Editor/main.vue';

export default {
    name: 'SettingDesign',
    components: {
        TemplateDesign,
        // Editor,
        QuillEditor
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            tabDesign: [
                { id: 1, name: 'Design 1' },
                { id: 2, name: 'Design 2' }
            ]
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        }
    }
};
</script>

<style lang="scss" scoped></style>
