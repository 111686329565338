<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingPage.pageType') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-page`"
                            :value.sync="dataForm.setting_page.page_type"
                            :options="listPage"
                            :config="configForm.configFieldSelectPage"
                            @remove="handleValidateField(configForm.configFieldSelectPage)"
                        />
                    </div>
                </div>
                <h5 class="my-3">{{ dataForm.setting_page.page_type?.name ?? '' }}</h5>
                <div v-if="dataForm.setting_page.page_type?.name" class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-9">
                        <div v-for="(master_page, idx) in dataForm.setting_page.pages" :key="idx">
                            <div class="form-group row">
                                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ master_page.title }}</label>
                                <div v-if="master_page.is_accept_add" class="col-sm-9">
                                    <button type="button" class="btn btn-outline-info btn-sm rounded-circle" @click="handleAddFormInput(master_page)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-for="(page, k) in master_page.page_child" :key="k" class="form-group row">
                                <label class="col-sm-10 text-nowrap" style="min-width: 150px">{{ page.name_page }}</label>
                                <div class="col-sm-2 d-flex flex-wrap">
                                    <button
                                        type="button"
                                        class="btn btn-outline-warning btn-sm rounded-circle mr-3 mb-2"
                                        style="width: 30px; height: 30px"
                                        @click="handleEditFormInput(page, idx, k)"
                                    >
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button
                                        :disabled="master_page.page_child.length == 1"
                                        type="button"
                                        class="btn btn-outline-danger btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleDeleteFormInput(k, master_page.page_child)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <ModalAddPage :configModal="configModalAddPage" />
        <modalEditPage :configModal="configModalEditPage" />
    </div>
</template>

<script>
import { configModalAddPage, configModalEditPage } from './constants';
import { validateField } from '@/utils/validate';

import Multiselect from '@/components/Multiselect/main.vue';
import ModalAddPage from './modal-add-page.vue';
import modalEditPage from './modal-edit-page.vue';

export default {
    name: 'SettingPage',
    components: {
        Multiselect,
        ModalAddPage,
        modalEditPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        },
        listPage: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            configModalAddPage: configModalAddPage,
            configModalEditPage: configModalEditPage
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddFormInput(obj) {
            this.configModalAddPage.model = true;
            this.configModalAddPage.pageAdd = obj.page_child;
        },

        handleEditFormInput(obj, idx, k) {
            this.configModalEditPage.model = true;
            this.configModalEditPage.id = `edit_page-${idx}_${k}`;
            this.configModalEditPage.title = obj.name_page;
        },

        handleDeleteFormInput(k, arrayPage) {
            arrayPage.splice(k, 1);
        }
    }
};
</script>

<style lang="scss" scoped></style>
