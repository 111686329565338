<template>
    <div>
        <ModalCommon :id="`${configModal?.id}`" :config="configModal" @save="handleSave()" @close="handleClose()" :isView="isViewSetting">
            <div class="form-group row flex-nowrap">
                <label class="col-sm-3 text-nowrap" style="min-width: 280px"
                    >{{ $t('managerForm.settingPage.titlePage') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9" style="min-width: 815px">
                    <InputText :model.sync="configModal.title" :config.sync="configFieldNamePage" @blur="handleValidateField(configFieldNamePage)" />
                </div>
            </div>
            <!-- block -->
            <div class="form-group row flex-nowrap">
                <div class="col-sm-3" style="min-width: 280px">
                    <div>
                        <p style="background: #808080; font-size: 12px; background: #e5e5e5; font-weight: normal; padding: 8px 15px 8px 20px">
                            設定項目追加
                        </p>
                        <div class="d-flex align-items-center p-3" style="gap: 12px">
                            <Multiselect
                                :id="`select-block`"
                                :value.sync="select_block"
                                :options="listBlock"
                                :config="configFieldSelectBlock"
                                @remove="handleRemoveBlock(configFieldSelectBlock)"
                            />

                            <button class="btn btn-success text-nowrap" type="button" @click="handleAddBlock">{{ $t('btn.addTag') }}</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9" style="min-width: 815px">
                    <SettingBlockForm
                        v-if="isSettingBlock"
                        :config="configSettingBlock"
                        :formBlock="initFormBlock"
                        :configFormInput="configFormInput"
                        :actionTimeAddBlock="actionTimeAddBlock"
                        @showSave="handleShowSaveBlock"
                    />
                    <div v-else>
                        <div class="white_wrap mb-3">
                            <div class="row" style="gap: 12px">
                                <p
                                    class="btn mb-0 h-100"
                                    :class="activePreview == 'PC' ? 'btn-outline-success' : ''"
                                    @click="handleSwitchDevice('PC')"
                                >
                                    <i class="fa fa-laptop"></i> PC
                                </p>
                                <p
                                    class="btn mb-0 h-100"
                                    :class="activePreview == 'Mobile' ? 'btn-outline-success' : ''"
                                    @click="handleSwitchDevice('Mobile')"
                                >
                                    <i class="fa fa-mobile"></i> Mobile
                                </p>
                                <div class="ml-auto">
                                    <div class="d-flex align-items-center justify-content-end mr-3 mb-2">
                                        <InputText
                                            class="mr-1"
                                            style="max-width: 100px"
                                            :model.sync="pixel_input"
                                            :config="{
                                                isNumber: false,
                                                error: false
                                            }"
                                            @change="handleInputPixel"
                                        />
                                        <div>px</div>
                                    </div>
                                    <div style="font-size: 12px">プレビューの横幅には300～2000の整数を入力してください。</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right">画面サイズは横幅{{ pixel_action ? `${pixel_action}px` : '100%' }}で表示されています</div>

                        <div class="ifame_page d-flex justify-content-center">
                            <iframe
                                :src="`${originPath}/manager-form/iframe-page`"
                                :width="`${pixel_action ? pixel_action : '100%'}`"
                                height="520"
                                frameborder="0"
                                allowfullscreen
                                scrolling="auto"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import SettingBlockForm from '../setting-block-form/main.vue';

import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { BLOCK_COMMON } from '@/config/var-common';
import { initFormBlock } from './constants';
import { configFormInput } from '../setting-block-form/constants';
export default {
    name: 'ModalEditPage',
    components: {
        ModalCommon,
        InputText,
        Multiselect,
        SettingBlockForm
    },
    props: {
        configModal: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            initFormBlock: initFormBlock(),
            configFormInput: configFormInput(),
            activePreview: 'PC',
            select_block: null,
            pixel_input: '',
            pixel_action: '',
            originPath: window.location.origin,
            listBlock: BLOCK_COMMON,
            isSettingBlock: false,
            configSettingBlock: null,
            isViewSetting: false,
            actionTimeAddBlock: 0,
            configFieldSelectBlock: {
                trackBy: 'id',
                label: 'name',
                errorField: this.$t('managerForm.settingPage.pageType'),
                valueSync: null,
                error: false,
                isRemove: false,
                isRequired: false,
                typesValidate: []
            },
            configFieldNamePage: {
                isNumber: false,
                isMaxLength: false,
                error: false,
                errorField: this.$t('managerForm.settingPage.titlePage'),
                errorCustomMess: '',
                valueSync: '',
                typesValidate: ['required', 'max255']
            }
        };
    },
    methods: {
        handleSave() {
            let check = validateField(this.configFieldNamePage);
            if (!check) return;
            console.log('this.initFormBlock', this.initFormBlock);

            this.configModal.model = false;
            this.isSettingBlock = false;
            this.select_block = null;
        },

        handleClose() {
            this.isSettingBlock = false;
            this.select_block = null;
            this.configModal.model = false;
            this.configFieldNamePage.error = false;
            this.activePreview = 'PC';
            this.pixel_input = '';
            this.pixel_action = '';
        },

        handleRemoveBlock(configFieldSelectBlock) {
            this.isViewSetting = false;
            this.isSettingBlock = false;
            this.handleValidateField(configFieldSelectBlock);
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddBlock() {
            this.actionTimeAddBlock = Math.random();
            if (!this.select_block?.id) return;
            this.isViewSetting = true;
            if (this.select_block.type == 'postcode-and-province') {
                this.isSettingBlock = false;
                return;
            }

            this.isSettingBlock = true;
            this.configSettingBlock = this.select_block;
            this.initFormBlock = initFormBlock();
            this.configFormInput = configFormInput();
        },

        handleSwitchDevice(device) {
            this.activePreview = device;
            this.pixel_input = device == 'PC' ? '' : device == 'Mobile' ? '400' : '';
            this.pixel_action = this.pixel_input;
        },

        handleInputPixel(value) {
            this.activePreview = '';
            if (value < 300) {
                this.pixel_input = 300;
            } else if (value > 2000) {
                this.pixel_input = 2000;
            }
            this.pixel_action = this.pixel_input;
        },

        handleShowSaveBlock(isShow) {
            this.isViewSetting = isShow;
            this.isSettingBlock = isShow;
            this.select_block = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-outline-success:hover {
    color: #1cbb8c;
    border-color: #1cbb8c;
    background-color: #fff;
}
</style>
