import i18n from '@/i18n';
import { state } from '@/state/modules/authfack.js';

const campaignDefault = state?.user?.default_campaign ?? null;

const TABS = [
    {
        id: 1,
        name: '基本設定 - cơ bản',
        disabled: false
    },
    {
        id: 2,
        name: 'デザイン設定 - thiết kế',
        disabled: false
    },
    {
        id: 3,
        name: 'ブロック設定 - block',
        disabled: false
    },
    {
        id: 4,
        name: 'ファーストビュー - first view',
        disabled: false
    },
    {
        id: 5,
        name: '応募期間 - thời hạn ứng tuyển',
        disabled: false
    },
    {
        id: 6,
        name: '賞品設定 - phần thưởng',
        disabled: false
    },
    {
        id: 7,
        name: '対象商品 - sản phẩm mục tiêu',
        disabled: false
    },
    {
        id: 8,
        name: '応募方法設定 - phương thức ứng tuyển',
        disabled: false
    },
    {
        id: 9,
        name: 'レシート撮影の注意事項 - điều khoản',
        disabled: false
    },
    {
        id: 10,
        name: '応募規約 - quy định ứng tuyển',
        disabled: false
    },
    {
        id: 11,
        name: 'シェアブロック - share block',
        disabled: false
    },
    {
        id: 12,
        name: 'お問い合わせ - liên hệ đối ứng',
        disabled: false
    },
    {
        id: 13,
        name: 'ボタンブロック一覧 - danh sách button',
        disabled: false
    },
    {
        id: 14,
        name: '追加ブロック一覧 - danh sách block thêm',
        disabled: false
    },
    {
        id: 15,
        name: '年齢確認ブロック - Xác minh độ tuổi',
        disabled: false
    }
    // {
    //     id: 16,
    //     name: 'ボタンブロックでの公開前・公開後切り替え - Chuyển đổi nút',
    //     disabled: false
    // },
    // {
    //     id: 17,
    //     name: 'LPの公開前後でのグレーアウト - Màu xám',
    //     disabled: false
    // },
    // {
    //     id: 18,
    //     name: 'ボタンブロックでの公開前・公開後切り替え - Xác thực cơ bản',
    //     disabled: false
    // }
];

const SOCIAL_MEDIA_SHARE = [
    {
        id: 1,
        name: 'Facebook',
        key: 'facebook'
    },
    {
        id: 2,
        name: 'Line',
        key: 'line'
    },
    {
        id: 3,
        name: 'X',
        key: 'X'
    },
    {
        id: 4,
        name: 'Instagram',
        key: 'instagram'
    },
    {
        id: 5,
        name: 'Tiktok',
        key: 'tiktok'
    },
    {
        id: 6,
        name: 'Pinterest',
        key: 'pinterest'
    }
];

const STATUS_SETTING_BASIC = [
    {
        id: 1,
        value: '公開'
    },
    {
        id: 2,
        value: '下書き'
    },
    {
        id: 3,
        value: '終了'
    }
];

const STATUS_SETTING_PRIZE = [
    {
        id: '0',
        value: '有効'
    },
    {
        id: 1,
        value: '無効の切り替え'
    }
];

const STATUS_FORM = [
    {
        id: 1,
        value: '公開前'
    },
    {
        id: 2,
        value: '終了後'
    },
    {
        id: 3,
        value: '下書き'
    }
];

const configs = {
    campaign: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    nameLandingPage: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    client: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false
    },
    configTable: {
        headers: [
            {
                label: i18n.t('landingPage.list.lpID')
            },
            {
                label: i18n.t('landingPage.list.nameCampaign')
            },
            {
                label: i18n.t('landingPage.list.nameLandingPage')
            },
            {
                label: i18n.t('landingPage.list.urlPublic')
            },
            {
                label: i18n.t('landingPage.list.status')
            },
            {
                label: i18n.t('landingPage.list.startDateLP')
            },
            {
                label: i18n.t('landingPage.list.endDateLP')
            }
        ]
    },
    configPage: {
        total: 0,
        limit: 10,
        page: 1
    }
};

const LIST_FONT_PAGE = [
    {
        id: 1,
        name: 'Yuji Mai, serif',
        url: 'https://fonts.googleapis.com/css2?family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Yuji Mai', serif",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    },
    {
        id: 2,
        name: 'Noto Sans JP, sans-serif',
        url: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Yuji Mai', serif",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    },
    {
        id: 3,
        name: 'Hachi Maru Pop, cursive',
        url: 'https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Noto+Sans+JP:wght@100..900&family=Yuji+Mai&display=swap',
        property_style: {
            fontFamily: "'Hachi Maru Pop', cursive",
            fontWeight: 400,
            fontStyle: 'normal'
        }
    }
];

const SQUARE_TYPE_PAGE = [
    {
        id: 1,
        value: 'スクエア'
    },
    {
        id: 2,
        value: 'サークル'
    }
];

const LIST_STYLE_TIME = [
    {
        id: 1,
        name: 'A',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image1.png',
        contents:
            '<div class="box--time__1" style="background-image: url(《sourceBgr》);background-repeat: no-repeat;background-size: contain;background-position: center;height: 197px;width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #ffffff; color: #36a6b4; border-radius: 10px">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h4 style="color: #ffffff">《開始》</h4></div><div style="text-align: center; margin-block: 2px; color: #ffffff">▼</div><div style="display: flex; align-items: center; gap: 10px"><h4 style="color: #ffffff">《終了》</h4></div></div></div></div></div>'
    },
    {
        id: 2,
        name: 'B',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image2.png',
        contents:
            '<div class="box--time__2" style="background-image: url(《sourceBgr》); background-repeat: no-repeat; background-size: contain; background-position: center; width: 100%;padding: 74px 0px"><div><div><h3 style="text-align: center; color: #ffffff;">《タイトル》</h3></div><div style="display: flex; justify-content: space-around; align-items: center"><div class=""><div><h5 style="color: #ffffff;text-align: center">《開始》</h5></div><div style="text-align: center; margin-block: 0px; color: #ffffff; font-size: 20px">▼</div><div style=""><h5 style="color: #ffffff;text-align: center;">《終了》</h5></div></div></div></div></div>'
    },
    {
        id: 3,
        name: 'C',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image3.png',
        contents:
            '<div class="box--time__3" style="background-image: url(《sourceBgr》); background-repeat: no-repeat; background-size: contain; background-position: center; height: 195px; width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #5d6b8f; color: #ffffff; border-radius: 10px">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h4 style="color: #5d6b8f">《開始》</h4></div><div style="text-align: center; margin-block: 2px; color: #5d6b8f">▼</div><div style="display: flex; align-items: center; gap: 10px"><h4 style="color: #5d6b8f">《終了》</h4></div></div></div></div></div>'
    },
    {
        id: 4,
        name: 'D',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image4.png',
        contents:
            '<div class="box--time__4" style="background-image: url(《sourceBgr》); background-repeat: no-repeat; background-size: contain; background-position: center; height: 230px; width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #947be8; color: #ffffff; border-radius: 50%; width: 100px; height: 100px; text-align: center; display: flex; justify-content: center; align-items: center;">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h4 style="color: #947be8">《開始》</h4></div><div style="text-align: center; margin-block: 2px; color: #947be8">▼</div><div style="display: flex; align-items: center; gap: 10px"><h4 style="color: #947be8">《終了》</h4></div></div></div></div></div>'
    }
];

const LIST_STYLE_PRIZE = [
    {
        id: 1,
        name: 'A',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image7.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/image5.png',
        contents:
            '<div style="position: relative;display:《display》"><img src="《sourceBgr》" alt="" style="width: 100%; height: 100%" /><div style="position: absolute; top: 30px; left: 15px; transform: rotate(-45deg); width: 85px; overflow: hidden; white-space: nowrap"><h4>《タイトル》</h4></div><div style="position: absolute; width: 280px; height: 150px; top: 40%; left: 50%; transform: translate(-50%, -40%)"><img src="《imgTitle》" alt="" style="width: 100%; height: 100%;object-fit: cover;" /></div><div style="position: absolute; bottom: 0; left: 5px; padding: 20px"><h2 style="color: #000000;width: 200px;overflow: hidden;">《詳細》</h2></div><div style="position: absolute; bottom: 0; right: 15px; padding: 20px"><h2 style="color: #36a6b4">《当選人数》名様</h2></div></div>'
    },
    {
        id: 2,
        name: 'B',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image8.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/image6.png',
        contents:
            '<div> <div style="position: relative; display: 《display》"><img src="《sourceBgr》"alt=""style="width: 100%; height: 100%"/><div style="position: absolute;top: 30px;left: 50%;transform: translateX(-50%);width: 140px;overflow: hidden;white-space: nowrap;"><h4 style="color: #efea3a; font-size: 2rem; font-style: normal; font-weight: 700; line-height: normal; text-align: center">《タイトル》</h4></div><div style="position: absolute; width: 280px; height: 150px; top: 50%; left: 50%; transform: translate(-50%, -50%)"><img src="《imgTitle》" alt="" style="width: 100%; height: 100%; object-fit:cover;" /></div><div style="position: absolute; bottom: 0; left: 0; padding: 20px"><h2 style="color: #000000">《詳細》</h2></div><div style="position: absolute; bottom: 0; right: 15px; padding: 20px"><h2 style="color: #25ab38">《当選人数》名様</h2></div></div></div>'
    }
];

const LIST_STYLE_TARGET_PRODUCT = [
    {
        id: 1,
        name: 'A',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/1739269314_7y3UQUFbng_style_prize_1.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/1739332647_rVOEqjEQLn_1212.png',
        contents: ''
    },
    {
        id: 2,
        name: 'B',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/1739334790_L2PGFBYFUa_stype_prize_2.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/1739333660_GNF2r28m0T_Group.png',
        contents: ''
    }
];

const LIST_STYLE_APPLICATION_METHODS = [
    {
        id: 1,
        name: 'A',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/1739269314_7y3UQUFbng_style_prize_1.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/1739332647_rVOEqjEQLn_1212.png',
        contents: ''
    },
    {
        id: 2,
        name: 'B',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/1739334790_L2PGFBYFUa_stype_prize_2.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/1739333660_GNF2r28m0T_Group.png',
        contents: ''
    }
];

const LIST_STYLE_CONTACT = [
    {
        id: 1,
        name: 'A',
        srcBgr: '',
        imgSection: '',
        contents: ''
    },
    {
        id: 2,
        name: 'B',
        srcBgr: '',
        imgSection: '',
        contents: ''
    }
];

const LIST_STYLE_BUTTON_BLOCK = [
    {
        id: 1,
        name: 'A'
    },
    {
        id: 2,
        name: 'B'
    }
];

const initFormSearch = () => {
    return {
        campaign_name: null,
        name: null,
        sale_staff: '',
        campaign_start_date: '',
        campaign_end_date: '',
        lp_start_date: '',
        lp_end_date: ''
    };
};

const initFormRegister = () => {
    return {
        //----- setting basic --------------
        campaign: campaignDefault,
        code: '',
        name: '',
        number_cp: '',
        sale_staff: '',
        person_in_charge: '',
        zac_id: '',
        sfid: '',
        campaign_entity_id: '',
        //  start date cp
        campaign_start_date: '',
        campaign_start_time: '',
        //  end date cp
        campaign_end_date: '',
        campaign_end_time: '',
        //  start date lp
        lp_start_date: '',
        lp_start_time: '',
        //  end date lp
        lp_end_date: '',
        lp_end_time: '',
        status: '',
        keywords: [],
        description: '',
        analytics: '',
        og_image: null,
        url_public: '',
        //----- end setting basic --------------

        //----- start setting design --------------
        design_settings: {
            // 1 SettingOverall
            font_page: '',
            square_type: '',
            background_color: '',
            title_color: '',
            font_color: '',
            button_color: ''
        },
        //----- end setting design --------------

        //----- start setting blocks --------------
        blocks_settings: [
            {
                order_index: 0,
                name: 'First view',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'first_view'
            },
            {
                order_index: 1,
                name: 'Thời hạn ứng tuyển',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'application_period'
            },
            {
                order_index: 2,
                name: 'Phần thưởng',
                category: '',
                is_visibled: true,
                block_default: true,
                key: 'block_prize'
            },
            {
                order_index: 3,
                name: 'block được đăng kí bên ngoài',
                category: '',
                is_visibled: true,
                block_default: false,
                key: ''
            }
        ],
        //----- end setting blocks --------------

        //----- start setting firstview --------------
        first_view: {
            html_content: ''
        },
        //----- end setting firstview --------------

        //----- start setting application period  --------------
        application_period: {
            title: '応募期間',
            style: null,
            start_date: '',
            end_date: '',
            html_content: ''
        },
        //----- end setting application period  --------------

        //----- start setting prize settings  --------------
        prize_settings: {
            title: '応募期間',
            background: {
                image: '',
                color: '#ffffff'
            },
            is_repeat: false,
            style: {
                id: 1,
                name: 'A'
            },
            html_content: '',
            prizes: []
        },
        //----- end setting prize settings  --------------

        //----- start setting target product settings  --------------
        block_target_product: {
            title_block: 'Trương trình quay số trúng thưởng !',
            background_image: null,
            background_color: '',
            is_repeat: false,
            style: null,
            target_products: [],
            html_content: ''
        },
        //----- end setting target product settings  --------------

        //----- start setting application method settings  --------------
        block_application_method: {
            title_block: 'Trương trình PHƯƠNG THỨC ỨNG TUYỂN !',
            background_image: null,
            background_color: '',
            is_repeat: false,
            style: null,
            application_methods: [],
            html_content: ''
        },
        //----- end setting application method settings  --------------

        //----- start setting receipt photography settings  --------------
        block_receipt_photography_tutorial: '',
        //----- end setting receipt photography settings  --------------

        //----- start setting application rule settings  --------------
        block_application_rule: {
            title_block: 'Quy định ứng tuyển',
            background_image: null,
            background_color: '#ffffff',
            is_repeat: false,
            html_content: ''
        },
        //----- end setting application rule settings  --------------

        //----- start setting share settings  --------------
        block_share: {
            title_block: 'Chia sẻ block !',
            background_image: null,
            background_color: '#ffffff',
            is_repeat: false,
            url: '',
            share_text: '',
            html_content: '',
            hashtag: [],
            platform: {
                facebook: false,
                line: false,
                x: false,
                instagram: false,
                tiktok: false,
                pinterest: false
            }
        },
        //----- end setting share settings  --------------

        //----- start setting contact settings  --------------
        block_contact: {
            title_block: 'Khối liên hệ !',
            background_image: null,
            background_color: '#ffffff',
            is_repeat: false,
            style: null,
            contact_secretariat_name: '',
            tel: '',
            mail: '',
            html_content: ''
        },
        //----- end setting contact settings  --------------

        //----- start setting free blocks settings  --------------
        free_blocks: [],
        //----- start setting free blocks settings  --------------

        //----- start setting button blocks settings  --------------
        button_blocks: []
        //----- start setting button blocks settings  --------------
    };
};

export {
    TABS,
    STATUS_FORM,
    configs,
    LIST_FONT_PAGE,
    SQUARE_TYPE_PAGE,
    STATUS_SETTING_BASIC,
    STATUS_SETTING_PRIZE,
    LIST_STYLE_TIME,
    LIST_STYLE_PRIZE,
    LIST_STYLE_TARGET_PRODUCT,
    LIST_STYLE_APPLICATION_METHODS,
    LIST_STYLE_CONTACT,
    SOCIAL_MEDIA_SHARE,
    LIST_STYLE_BUTTON_BLOCK,
    initFormSearch,
    initFormRegister
};
