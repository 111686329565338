<template>
    <div>
        <textarea
            v-model="dataTextarea"
            :id="id"
            :autofocus="configDefault.autofocus"
            :cols="cols"
            :dirname="configDefault.dirname"
            :form="configDefault.form"
            :maxlength="configDefault.maxlength"
            :name="name"
            :placeholder="configDefault.placeholder"
            :readonly="configDefault.readonly"
            :required="configDefault.required"
            :rows="rows"
            :wrap="configDefault.wrap"
            class="form-control"
            :class="{
                [configDefault.classCustom]: configDefault.classCustom,
                'is-invalid': configDefault.error,
                disabled_cursor: disabled
            }"
            :disabled="disabled"
            @blur="handleBlur"
        ></textarea>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'TextareaComponent',
    props: {
        id: {
            type: String,
            default: () => 'textarea-form'
        },
        name: {
            type: String,
            default: () => ''
        },
        model: {
            required: true,
            default: () => ''
        },
        rows: {
            default: () => ''
        },
        cols: {
            default: () => ''
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        config: {
            type: Object,
            default: () => {
                return {
                    autofocus: 'autofocus',
                    dirname: '',
                    form: 'form_id',
                    maxlength: '',
                    placeholder: '',
                    wrap: '',
                    valueSync: '',
                    errorCustomMess: '',
                    classCustom: '',
                    typesValidate: [],
                    error: true,
                    readonly: false,
                    required: false
                };
            }
        }
    },
    data() {
        return {
            dataTextarea: '',
            configDefault: this.config
        };
    },
    methods: {
        handleBlur() {
            this.$emit('blur');
        }
    },
    watch: {
        model: {
            handler: function (newModel) {
                this.dataTextarea = newModel;
            },
            immediate: true
        },

        configDefault: {
            handler: function (newConfig) {
                this.$emit('update:config', newConfig);
            },
            immediate: true,
            deep: true
        },

        dataTextarea: {
            handler: function () {
                this.configDefault.errorCustomMess = '';
                this.configDefault.error = false;
                this.configDefault.valueSync = this.dataTextarea;

                this.$emit('update:model', this.dataTextarea);
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
