import { render, staticRenderFns } from "./main.vue?vue&type=template&id=4b6e0833&scoped=true"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6e0833",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/paldia-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b6e0833')) {
      api.createRecord('4b6e0833', component.options)
    } else {
      api.reload('4b6e0833', component.options)
    }
    module.hot.accept("./main.vue?vue&type=template&id=4b6e0833&scoped=true", function () {
      api.rerender('4b6e0833', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/phase2/manager-form/components/setting-digital_gift/main.vue"
export default component.exports