import i18n from '@/i18n';
import { initFormBlock } from '../setting-page/constants';

const configFormInput = () => {
    return {
        configFieldNameBlock: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.nameBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldLabelBlock: {
            isRequired: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.label'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required'],
            editorOption: {
                placeholder: ''
            }
        },
        configFieldLabel_Q_Block1: {
            isRequired: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.label'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required'],
            editorOption: {
                placeholder: ''
            }
        },
        configFieldLabel_A_Block2: {
            isRequired: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.label'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required'],
            editorOption: {
                placeholder: ''
            }
        },
        configFieldMaxCharacters: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.maxCharacters'),
            errorCustomMess: '',
            valueSync: '',
            min: 1,
            max: 255,
            typesValidate: ['required', 'max255', 'limit-input']
        },
        configFieldPhonePlaceholder_1: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPhonePlaceholder_2: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPhonePlaceholder_3: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPlaceholderYear: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPlaceholderMonth: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: '月',
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPlaceholderDay: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: '日',
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldLowerLimit: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.lowerLimit'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldUpperLimit: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.upperLimit'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldMaximumFileSize: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.maximumFileSize'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldAllowedExtensions: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.allowedExtensions'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldMinimumNumber: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.minimumNumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldMaximumNumber: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.maximumNumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldInputPlaceholder: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldInputLastNamePlaceholder: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.lastName'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldInputFirstNamePlaceholder: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.firstName'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPostcodePlaceholder1: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPostcodePlaceholder2: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderCommon'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldRowTextarea: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.rowsTextarea'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldColumnsTextarea: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.colsTextarea'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldInputFieldSize: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.inputFieldSize'),
            errorCustomMess: '',
            valueSync: '',
            min: 1,
            max: 200,
            typesValidate: ['max255', 'limit-input']
        },
        configFieldPlaceholder: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.placeholderSelect'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldLabelOther: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.labelOther'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldWidthOther: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.widthOther'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldLabelUncheck: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.uncheck'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldNumberLimitCheck: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.checkNumberLimit'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldRemainingLabelOther: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.labelOther'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldRemainingRatio: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.remainingRatio'),
            errorCustomMess: '',
            valueSync: '',
            min: '1',
            max: '99',
            typesValidate: ['max255', 'limit-input']
        },
        configFieldRemainingText: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.remainingText'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldTextAfterremainingnumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.textAfterremainingnumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldKeywordUpload: {
            error: false,
            errorField: i18n.t('managerForm.settingPage.keywordUpload'),
            errorCustomMess: '',
            valueSync: '',
            length: '',
            typesValidate: ['requiredCustomMsg', 'maxCustom', 'max255']
        },
        configFieldParamName: {
            error: false,
            errorField: i18n.t('managerForm.settingPage.paramName'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldParamUrl: {
            error: false,
            errorField: i18n.t('managerForm.settingPage.errorRedirectDestination'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },

        configFieldEditWithEditor: {
            isRequired: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.editWithEditor'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: [],
            editorOption: {
                placeholder: ''
            }
        },

        validateArrayOption: initFormBlock().option_value.map((o) => {
            return {
                configFieldValue: {
                    isRequired: false,
                    error: false,
                    errorField: TABLE_OPTION_BLOCK_COMMON.headers[1].label,
                    errorCustomMess: '',
                    valueSync: o.value ? o.value : '',
                    typesValidate: ['required', 'max255']
                },
                configFieldNumberOfReceptions: {
                    isNumber: true,
                    isRequired: false,
                    error: false,
                    errorField: TABLE_OPTION_BLOCK_COMMON.headersRemaining[2].label,
                    errorCustomMess: '',
                    valueSync: o.value ? o.value : '',
                    min: 1,
                    max: 100000,
                    typesValidate: ['required', 'max255', 'limit-input']
                }
            };
        }),

        validateArrayErrMsg: initFormBlock().additional_setting.error_message_block.map((o) => {
            return {
                configFieldErrorMsg: {
                    isRequired: false,
                    error: false,
                    errorField: o.label,
                    errorCustomMess: '',
                    valueSync: o.messages ? o.messages : '',
                    typesValidate: ['required', 'max255']
                }
            };
        })
    };
};

const handleAddConfigOptions = (validateArrayOption, optionValue) => {
    validateArrayOption = optionValue.map((o, idx) => {
        const varConfigValue = validateArrayOption[idx]?.configFieldValue;
        return {
            configFieldValue: {
                isRequired: varConfigValue?.isRequired ?? false,
                error: varConfigValue?.error ?? false,
                errorField: varConfigValue?.errorField ?? TABLE_OPTION_BLOCK_COMMON.headers[1].label,
                errorCustomMess: varConfigValue?.errorCustomMess ?? '',
                valueSync: o.value ? o.value : '',
                typesValidate: varConfigValue?.typesValidate ?? ['required', 'max255']
            },
            configFieldNumberOfReceptions: {
                isNumber: varConfigValue?.isNumber ?? true,
                isRequired: varConfigValue?.isRequired ?? false,
                error: varConfigValue?.error ?? false,
                errorField: varConfigValue?.errorField ?? TABLE_OPTION_BLOCK_COMMON.headersRemaining[2].label,
                errorCustomMess: varConfigValue?.errorCustomMess ?? '',
                valueSync: o.value ? o.value : '',
                min: varConfigValue?.min ?? 1,
                max: varConfigValue?.max ?? 100000,
                typesValidate: varConfigValue?.typesValidate ?? ['required', 'max255', 'limit-input']
            }
        };
    });
    return validateArrayOption;
};

const CONFIRMATION_PAGE_DISPLAY = [
    {
        id: 1,
        name: '表示する'
    },
    {
        id: 2,
        name: '表示しない'
    }
];

const LIST_TEXT_ALIGN = [
    {
        id: 1,
        value: 'left'
    },
    {
        id: 2,
        value: 'center'
    },
    {
        id: 3,
        value: 'right'
    }
];

const LAYOUT_TYPE = [
    {
        id: 1,
        value: '共通デザインを適用する'
    },
    {
        id: 2,
        value: '横並び'
    },
    {
        id: 3,
        value: '縦並び'
    }
];

const LIST_CHOICE_CONDITION = [
    {
        id: 1,
        value: 'AND条件'
    },
    {
        id: 2,
        value: 'OR条件'
    }
];

const MATCH_CHOICE_CONDITION = [
    {
        id: 1,
        value: '入力不可'
    },
    {
        id: 2,
        value: '非表示'
    }
];

const TABLE_OPTION_BLOCK_COMMON = {
    headers: [
        {
            label: 'ID',
            required: false
        },
        {
            label: '選択肢',
            required: true
        },
        {
            label: 'コマンド',
            required: false
        }
    ],
    headersRemaining: [
        {
            label: 'ID',
            required: false
        },
        {
            label: '選択肢',
            required: true
        },
        {
            label: '受付数',
            required: true
        },
        {
            label: 'コマンド',
            required: false
        }
    ]
};

const TABLE_CONDITION_BLOCK = {
    headers: [
        {
            label: '項目',
            width: '250'
        },
        {
            label: '条件',
            width: '250'
        },
        {
            label: '値',
            width: '250'
        },
        {
            label: 'コマンド',
            width: '200'
        }
    ]
};

const TABLE_ERROR_MESSAGES = {
    headers: [
        {
            label: 'エラー内容',
            width: '250'
        },
        {
            label: 'メッセージ',
            width: '250'
        },
        {
            label: 'コマンド',
            width: '250'
        }
    ]
};

const TYPE_CONDITION_IN_TABLE = [
    {
        id: 1,
        value: 'いずれか選択'
    },
    {
        id: 2,
        value: '回答なし'
    },
    {
        id: 3,
        value: '回答あり'
    }
];

const OPTION_LIMIT_CHECK = [
    {
        id: 1,
        value: '='
    },
    {
        id: 2,
        value: '≧'
    },
    {
        id: 3,
        value: '≦'
    }
];

const OPTION_INPUT_FORMAT = [
    {
        id: 1,
        value: '指定無し'
    },
    {
        id: 2,
        value: 'メールアドレス'
    },
    {
        id: 3,
        value: '数字(0-9)'
    },
    {
        id: 4,
        value: '半角英数字'
    },
    {
        id: 5,
        value: '全角文字'
    },
    {
        id: 6,
        value: 'ひらがな'
    },
    {
        id: 7,
        value: '全角カタカナ'
    },
    {
        id: 8,
        value: '半角カタカナ'
    },
    {
        id: 9,
        value: '数値'
    },
    {
        id: 10,
        value: '数値(小数点を含む)'
    }
];

const OPTION_INPUT_FORMAT_FULL_NAME = [
    {
        id: 1,
        value: '指定無し'
    },
    {
        id: 2,
        value: 'ひらがな'
    },
    {
        id: 3,
        value: 'カタカナ'
    },
    {
        id: 4,
        value: '全角カタカナ'
    },
    {
        id: 5,
        value: '半角カタカナ'
    }
];

const OPTION_YES_NO = [
    {
        id: 1,
        value: '使用する'
    },
    {
        id: 2,
        value: '使用しない'
    }
];

const OPTION_FORMAT_DATE = [
    {
        id: 1,
        value: 'テキスト'
    },
    {
        id: 2,
        value: 'セレクト'
    }
];

const OPTION_CALENDAR_WEST_TIME = [
    {
        id: 1,
        value: '西暦'
    },
    {
        id: 2,
        value: '和暦'
    },
    {
        id: 3,
        value: '西暦・和暦'
    }
];

const LIST_PROVINCE_BLOCK = [
    {
        id: 1,
        value: '北海道'
    },
    {
        id: 2,
        value: '青森県'
    },
    {
        id: 3,
        value: '岩手県'
    },
    {
        id: 4,
        value: '宮城県'
    },
    {
        id: 5,
        value: '秋田県'
    },
    {
        id: 6,
        value: '山形県'
    },
    {
        id: 7,
        value: '福島県'
    },
    {
        id: 8,
        value: '茨城県'
    },
    {
        id: 9,
        value: '栃木県'
    },
    {
        id: 10,
        value: '群馬県'
    },
    {
        id: 11,
        value: '埼玉県'
    },
    {
        id: 12,
        value: '千葉県'
    },
    {
        id: 13,
        value: '東京都'
    },
    {
        id: 14,
        value: '神奈川県'
    },
    {
        id: 15,
        value: '新潟県'
    },
    {
        id: 16,
        value: '富山県'
    },
    {
        id: 17,
        value: '石川県'
    },
    {
        id: 18,
        value: '福井県'
    },
    {
        id: 19,
        value: '山梨県'
    },
    {
        id: 20,
        value: '長野県'
    },
    {
        id: 21,
        value: '岐阜県'
    },
    {
        id: 22,
        value: '静岡県'
    },
    {
        id: 23,
        value: '愛知県'
    },
    {
        id: 24,
        value: '三重県'
    },
    {
        id: 25,
        value: '滋賀県'
    },
    {
        id: 26,
        value: '京都府'
    },
    {
        id: 27,
        value: '大阪府'
    },
    {
        id: 28,
        value: '兵庫県'
    },
    {
        id: 29,
        value: '奈良県'
    },
    {
        id: 30,
        value: '和歌山県'
    },
    {
        id: 31,
        value: '鳥取県'
    },
    {
        id: 32,
        value: '島根県'
    },
    {
        id: 33,
        value: '岡山県'
    },
    {
        id: 34,
        value: '広島県'
    },
    {
        id: 35,
        value: '山口県'
    },
    {
        id: 36,
        value: '徳島県'
    },
    {
        id: 37,
        value: '香川県'
    },
    {
        id: 38,
        value: '愛媛県'
    },
    {
        id: 39,
        value: '高知県'
    },
    {
        id: 40,
        value: '福岡県'
    },
    {
        id: 41,
        value: '佐賀県'
    },
    {
        id: 42,
        value: '長崎県'
    },
    {
        id: 43,
        value: '熊本県'
    },
    {
        id: 44,
        value: '大分県'
    },
    {
        id: 45,
        value: '宮崎県'
    },
    {
        id: 46,
        value: '鹿児島県'
    },
    {
        id: 47,
        value: '沖縄県'
    }
];

const DISPLAY_CONTENT_BLOCK_REMAINING = [
    {
        id: 1,
        name: '残数を表示'
    },
    {
        id: 2,
        name: '残数割合に応じてラベルを表示'
    }
];

const LIST_MATCHING_CONDITION = [
    {
        id: 1,
        value: '大文字/小文字を区別する'
    },
    {
        id: 2,
        value: '全角/半角を区別する'
    },
    {
        id: 3,
        value: 'ひらがな/カタカナを区別する'
    }
];

const OPTION_SOURCE_PARAMS = [
    {
        id: 1,
        value: 'パラメータ'
    },
    {
        id: 2,
        value: 'HTTPヘッダ'
    },
    {
        id: 3,
        value: 'Cookie'
    }
];

export {
    configFormInput,
    handleAddConfigOptions,
    CONFIRMATION_PAGE_DISPLAY,
    LIST_TEXT_ALIGN,
    LAYOUT_TYPE,
    LIST_CHOICE_CONDITION,
    MATCH_CHOICE_CONDITION,
    TABLE_OPTION_BLOCK_COMMON,
    TABLE_CONDITION_BLOCK,
    TYPE_CONDITION_IN_TABLE,
    OPTION_LIMIT_CHECK,
    OPTION_INPUT_FORMAT,
    OPTION_YES_NO,
    OPTION_FORMAT_DATE,
    OPTION_CALENDAR_WEST_TIME,
    OPTION_INPUT_FORMAT_FULL_NAME,
    LIST_PROVINCE_BLOCK,
    DISPLAY_CONTENT_BLOCK_REMAINING,
    LIST_MATCHING_CONDITION,
    OPTION_SOURCE_PARAMS,
    TABLE_ERROR_MESSAGES
};
