import { render, staticRenderFns } from "./action-option-common.vue?vue&type=template&id=0af7800e&scoped=true"
import script from "./action-option-common.vue?vue&type=script&lang=js"
export * from "./action-option-common.vue?vue&type=script&lang=js"
import style0 from "./action-option-common.vue?vue&type=style&index=0&id=0af7800e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af7800e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/paldia-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0af7800e')) {
      api.createRecord('0af7800e', component.options)
    } else {
      api.reload('0af7800e', component.options)
    }
    module.hot.accept("./action-option-common.vue?vue&type=template&id=0af7800e&scoped=true", function () {
      api.rerender('0af7800e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/phase2/manager-form/components/setting-block-form/action-option-common.vue"
export default component.exports