<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.optionsValue') }} </label>
            <div class="col-sm-9">
                <div class="d-flex mb-3">
                    <div>
                        <button type="button" class="btn btn-outline-success" @click="handleAddOneOption">
                            <i class="fa fa-plus"></i>
                            <span class="ml-2">{{ $t('managerForm.settingPage.addOneItem') }}</span>
                        </button>
                        <button type="button" class="btn btn-outline-success ml-3" @click="handleAddMoreOption">
                            <i class="fa fa-plus"></i>
                            <span class="ml-2">{{ $t('managerForm.settingPage.addAllItem') }}</span>
                        </button>
                    </div>
                    <div class="ml-auto">
                        <button
                            type="button"
                            class="btn btn-outline-secondary"
                            :disabled="formBlock.option_value.length <= 1"
                            @click="handleDeleteAllOption"
                        >
                            <i class="fa fa-minus"></i>
                            <span class="ml-2">{{ $t('managerForm.settingPage.addAllItem') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="isAddMoreOption">
                    <h5 class="text-center font-weight-bold mb-4">{{ $t('managerForm.settingPage.addAllAtOnce') }}</h5>
                    <div class="mb-3">
                        <span>{{ $t('managerForm.settingPage.noteAddAllAtOnce') }}</span>
                    </div>
                    <div class="mb-4">
                        <textarea
                            v-model="formBlock.text_area_more_option"
                            rows="5"
                            class="form-control"
                            placeholder="選択肢1
選択肢2
選択肢3"
                        ></textarea>
                    </div>
                    <div class="text-center">
                        <button type="button" class="btn btn-outline-light" @click="handleCancelMoreOption">
                            <span>{{ $t('managerForm.settingPage.cancel') }}</span>
                        </button>
                        <button type="button" class="btn btn-success ml-3" @click="handleSaveMoreOption">
                            <span>{{ $t('managerForm.settingPage.add') }}</span>
                        </button>
                    </div>
                </div>
                <div v-else class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <th v-for="(item, k) in HEADERS_TYPE" :key="k" style="background: #ededed">
                                <div class="d-flex align-items-center">
                                    {{ item.label }} <span v-if="item.required" class="ml-1 text-danger">*</span>
                                </div>
                            </th>
                        </thead>
                        <Draggable
                            :list="formBlock.option_value"
                            tag="tbody"
                            ghost-class="ghost--option"
                            @start="dragList = true"
                            @end="dragList = false"
                        >
                            <tr v-for="(itemOp, idx) in formBlock.option_value" :key="`tr-${idx}`">
                                <td>
                                    <div>
                                        {{ itemOp.id }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <InputText
                                            :model.sync="itemOp.value"
                                            :config.sync="configFormInput.validateArrayOption[idx].configFieldValue"
                                            @blur="handleValidateField(configFormInput.validateArrayOption[idx].configFieldValue)"
                                        />
                                    </div>
                                </td>
                                <td v-if="activeTypeRemaining">
                                    <div>
                                        <InputText
                                            :model.sync="itemOp.number_of_receptions"
                                            :config.sync="configFormInput.validateArrayOption[idx].configFieldNumberOfReceptions"
                                            @blur="handleValidateField(configFormInput.validateArrayOption[idx].configFieldNumberOfReceptions)"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div class="text-left">
                                        <button
                                            type="button"
                                            class="btn btn-outline-secondary"
                                            :disabled="formBlock.option_value.length <= 1"
                                            @click="handleDeleteOneOption(idx)"
                                        >
                                            <span>{{ $t('btn.delete') }}</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </Draggable>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TABLE_OPTION_BLOCK_COMMON, handleAddConfigOptions } from './constants';
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';

import Draggable from 'vuedraggable';
import Swal from 'sweetalert2';

export default {
    name: 'ActionOptionSelectCommon',
    components: {
        Draggable,
        InputText
    },
    props: {
        formBlock: {
            type: Object,
            required: true,
            default: () => null
        },
        configFormInput: {
            type: Object,
            required: true,
            default: () => null
        },
        typeBlock: {
            type: String,
            required: true,
            default: () => ''
        }
    },
    computed: {
        activeTypeRemaining() {
            return this?.typeBlock == 'remaining-seats-management-box';
        }
    },

    data() {
        return {
            TABLE_OPTION_BLOCK_COMMON: TABLE_OPTION_BLOCK_COMMON,
            HEADERS_TYPE: this.activeTypeRemaining ? TABLE_OPTION_BLOCK_COMMON.headersRemaining : TABLE_OPTION_BLOCK_COMMON.headers,
            isAddMoreOption: false,
            dragList: false
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddOneOption() {
            this.formBlock.option_value.push({ id: this.formBlock.option_value.length + 1, value: '', number_of_receptions: '' });
            this.configFormInput.validateArrayOption = handleAddConfigOptions(this.configFormInput.validateArrayOption, this.formBlock.option_value);
        },

        handleDeleteOneOption(index) {
            this.formBlock.option_value.splice(index, 1);
            this.configFormInput.validateArrayOption = handleAddConfigOptions(this.configFormInput.validateArrayOption, this.formBlock.option_value);
        },

        handleAddMoreOption() {
            this.isAddMoreOption = true;
        },

        handleCancelMoreOption() {
            this.formBlock.text_area_more_option = '';
            this.isAddMoreOption = false;
        },

        handleSaveMoreOption() {
            const resultArray = this.formBlock.text_area_more_option.split('\n').filter((line) => line.trim() !== '');
            for (let moreValue of resultArray) {
                this.formBlock.option_value.push({ id: this.formBlock.option_value.length + 1, value: moreValue, number_of_receptions: '' });
                this.configFormInput.validateArrayOption = handleAddConfigOptions(
                    this.configFormInput.validateArrayOption,
                    this.formBlock.option_value
                );
            }
            this.isAddMoreOption = false;
            this.formBlock.text_area_more_option = '';
        },

        handleDeleteAllOption() {
            if (this.formBlock.option_value.length > 1) {
                Swal.fire({
                    title: '項目をすべて削除します。よろしいですか？ ※公開済みの項目は削除されません。',
                    text: '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#34c38f',
                    cancelButtonColor: '#e8ecf4',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'キャンセル'
                }).then((result) => {
                    if (result.value) {
                        this.formBlock.option_value.splice(0, this.formBlock.option_value.length);
                        this.handleAddOneOption();
                        this.configFormInput.validateArrayOption = handleAddConfigOptions(
                            this.configFormInput.validateArrayOption,
                            this.formBlock.option_value
                        );
                    }
                });
            }
        }
    },

    watch: {
        activeTypeRemaining: {
            handler: function () {
                this.HEADERS_TYPE = this.activeTypeRemaining ? TABLE_OPTION_BLOCK_COMMON.headersRemaining : TABLE_OPTION_BLOCK_COMMON.headers;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.ghost--option {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
</style>
