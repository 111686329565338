import i18n from '@/i18n';

const configFormSerialCode = () => {
    return {
        configFieldSerialCode: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingSerialCode.registerSerialCode'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldNumberLimit: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingSerialCode.numberLimit'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
};

const configTable = {
    headers: [
        {
            label: i18n.t('managerForm.settingSerialCode.serialCode')
        },
        {
            label: i18n.t('managerForm.settingSerialCode.numberLimit')
        },
        {
            label: i18n.t('managerForm.settingSerialCode.numberUsed')
        },
        {
            label: ''
        }
    ]
};

export { configFormSerialCode, configTable };
