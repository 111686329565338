<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off" @submit.prevent="">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDigitalGift.title') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.title_digital"
                            :config.sync="configForm.configFieldTitle"
                            @blur="handleValidateField(configForm.configFieldTitle)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDigitalGift.topText') }}</label>
                    <div class="col-sm-9">
                        <textarea rows="3" v-model="dataForm.top_text_digital" class="form-control"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDigitalGift.bottomText') }}</label>
                    <div class="col-sm-9">
                        <textarea rows="3" v-model="dataForm.bottom_text_digital" class="form-control"></textarea>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDigitalGift.setting') }}</label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                                $t('managerForm.settingDigitalGift.selectedItem')
                            }}</label>
                            <div class="col-sm-9">
                                <textarea rows="3" v-model="dataForm.selected_item_digital" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                                $t('managerForm.settingDigitalGift.probabilityOfWinning')
                            }}</label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.probability_of_winning_digital"
                                    :config.sync="configForm.configFieldProbability"
                                    @blur="handleValidateField(configForm.configFieldProbability)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDigitalGift.result') }}</label>
                            <div class="col-sm-9">
                                <textarea rows="3" v-model="dataForm.result_digital" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" @click="handleAdd" class="btn btn-success ml-5">{{ $t('btn.addTag') }}</button>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingDigitalGift.rejectionText') }}</label>
                    <div class="col-sm-9">
                        <textarea rows="3" v-model="dataForm.rejection_text_digital" class="form-control"></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';

export default {
    name: 'SettingDigitalGift',
    components: {
        InputText
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {};
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleAdd() {}
    }
};
</script>

<style lang="sass" scoped></style>
